<template>
  <div>

    <div class="gva-search-box">
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
        <el-form-item label="所属接地箱id">
          <el-input v-model="searchInfo.boxId" placeholder="搜索条件" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button size="small" icon="refresh" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gva-table-box">
        <div class="gva-btn-list">
            <el-button size="small" type="primary" icon="plus" @click="openDialog">新增</el-button>
            <el-popover v-model:visible="deleteVisible" placement="top" width="160">
            <p>确定要删除吗？</p>
            <div style="text-align: right; margin-top: 8px;">
                <el-button size="small" text @click="deleteVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="onDelete">确定</el-button>
            </div>
            <template #reference>
                <el-button icon="delete" size="small" style="margin-left: 10px;" @click="deleteVisible=true" :disabled="!multipleSelection.length">删除</el-button>
            </template>
            </el-popover>
        </div>
        <el-table
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :data="tableData"
        row-key="id"
        fit="true"
        empty-text = "empty-text"
        height="700"
        @selection-change="handleSelectionChange"
        >
        <el-table-column type="selection" width="55" />
        <el-table-column align="left" label="id" prop="id" min-width="60" />
        <el-table-column align="left" label="设备名称" prop="name" min-width="120" />
        <el-table-column align="left" label="设备型号" prop="model.name" min-width="120" />
        <el-table-column align="left" label="线路"  min-width="120" >
            <template #default="scope">{{ getMapItemById(cables.idMap, scope.row.cableId).name }}</template>
          </el-table-column>
        <el-table-column align="left" label="所属接地箱" min-width="120" >
            <template #default="scope">{{ getMapItemById(cables.deviceIdMap, scope.row.boxId).name }}</template>
        </el-table-column>
        <el-table-column align="left" label="长度" prop="length" min-width="120" />
        <el-table-column align="left" label="设备使用年限" prop="service_life" min-width="120" />
        <el-table-column align="left" label="GPS模式"  min-width="120" >
            <template #default="scope">
            {{ getListItemByIndex(gpsModels, scope.row.gpsmodel).label }}
            </template>
          </el-table-column>
        <el-table-column align="left" label="经度" prop="longitude" min-width="120" />
        <el-table-column align="left" label="纬度" prop="latitude" min-width="120" />
        <el-table-column align="left" label="识别码" prop="userId" min-width="120"  v-if="false"/>
        <el-table-column align="left" label="子系统ID" prop="subSysId" min-width="120"  v-if="false"/>
        <el-table-column align="left" label="所属公司" prop="devCompany.name" min-width="120" />
        <el-table-column align="left" label="运维厂商" prop="maintenance.name" min-width="120" />
        <el-table-column align="left" label="生产厂家" prop="manufacture.name" min-width="120" />
        <el-table-column align="left" label="投运时间" prop="operationat" min-width="120" />
        <el-table-column align="left" label="序号" prop="queue" min-width="120" />
        <el-table-column align="left" label="devDataId" prop="devDataId" min-width="120" v-if="false"/>
        <el-table-column align="left" label="备注" prop="comment" min-width="120" />
        <el-table-column align="left" label="日期" min-width="180">
            <template #default="scope">{{ formatDate(scope.row.CreatedAt) }}</template>
        </el-table-column>
        <el-table-column align="left" label="设备唯一id" prop="uuid" min-width="120" show-overflow-tooltip="true" v-if="false"/>
        <el-table-column align="left" label="按钮组" min-width="140" >
            <template #default="scope">
            <el-button plain icon="edit" size="small" class="table-button" @click="updateDevDevicesFunc(scope.row)">变更</el-button>
            <el-button plain icon="delete" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <div class="gva-pagination">
            <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[15, 30, 50, 100]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            />
        </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog" @opened="onOpened()" title="弹窗操作">
      <el-form :model="formData" label-position="right" label-width="80px">
<el-card >
    <template #header>
      <div >
        <span>通用属性</span>
      </div>
    </template>

<el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="设备名称:">
            <el-input v-model="formData.name" clearable placeholder="请输入" />
        </el-form-item>
      </el-col>

      
      <el-col :span="12">
        <el-form-item label="设备型号:">
          <el-select v-model="formData.model" placeholder="Select" size="large"  value-key="name">
          <el-option
            v-for="item in models.list"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>
      </el-col>
</el-row>


        
        
<el-row :gutter="20">

      <el-col :span="12">
        <el-form-item label="线路:">
          <el-select v-model="formData.cable" placeholder="Select" size="large" value-key="name">
          <el-option
            v-for="item in cables.list"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>
      </el-col>

        
      <el-col :span="12">
        <el-form-item label="所属接地箱:" v-show="formData.model.type != 2">
          <el-select v-model="formData.ground" placeholder="Select" size="large"  value-key="name">
          <el-option
            v-for="item in formData.cable.gndboxs"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>
      </el-col>

</el-row>

<el-row :gutter="20">
      <el-col :span="7">

        <el-form-item label="长度:">
          <el-input-number v-model="formData.length"  :step="100" style="width:100%" :precision="0" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="7">

        <el-form-item label="投运时间:">
          <el-date-picker v-model="formData.operationAt" type="date" style="width:100%" placeholder="选择日期" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="8">

        <el-form-item label="设备使用年限:" label-width="50">
          <el-input v-model.number="formData.serviceLife" clearable placeholder="请输入" />
        </el-form-item>
      </el-col>
</el-row>

        <el-form-item label="设备所属厂商:">
          <el-select v-model="formData.devCompany" placeholder="Select" size="large"  value-key="name" style="width: 380px">
          <el-option
            v-for="item in companies.list"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>

        <el-form-item label="运维厂商:">
          <el-select v-model="formData.maintenance" placeholder="Select" size="large"  value-key="name" style="width: 380px">
          <el-option
            v-for="item in companies.list"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>
        

        <el-form-item label="生产厂家:">
          <el-select v-model="formData.manufacture" placeholder="Select" size="large" value-key="name" style="width: 380px">
          <el-option
            v-for="item in companies.list"
            :key="item.name"
            :label="item.name"
            :value="item"
          />
        </el-select>
        </el-form-item>

<el-row :gutter="20">
      <el-col :span="12">

      </el-col>
      <el-col :span="12">

      </el-col>
</el-row>
        <el-form-item label="备注:">
          <el-input v-model="formData.comment" clearable placeholder="请输入" />
        </el-form-item>


</el-card>
<el-divider/>
<el-card >
    <template #header>
      <div >
        <span>GPS配置</span>
      </div>
    </template>
<el-row :gutter="20">
      <el-col :span="8">

        <el-form-item label="GPS模式:">
          <el-select v-model="formData.gpsmodel" placeholder="类型" size="large">
          <el-option
          v-for="item in gpsModels"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
        </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="8" v-if="formData.gpsmodel == 1">
        <el-form-item label="经度:" >
          <el-input v-model="formData.longitude" clearable placeholder="请输入" />
        </el-form-item>
      </el-col>
      <el-col :span="8" v-if="formData.gpsmodel == 1">
        <el-form-item label="纬度:">
          <el-input v-model="formData.latitude" clearable placeholder="请输入" />
        </el-form-item>
      </el-col>
      
      <el-col :span="8"  v-if="formData.gpsmodel == 3">
        <el-form-item label="GPS设备ID:" label-width="50">
          <el-input v-model.number="formData.gpsDevID" clearable placeholder="请输入" />
        </el-form-item>
      </el-col>
</el-row>

</el-card>

      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterDialog">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
//import { getDictFunc, formatDate, formatBoolean, filterDict } from '@/utils/format'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, ref, shallowRef } from 'vue'
import service from '@/utils/request'
import { store } from "@/store";
import lodash from 'lodash'
import qs from 'qs'
import api from '@/api/devices'

export default {
  name: 'DevicesAddone',
  data() {
    const columnTableData = reactive([

    ]);
    const columnDataItem = reactive({
      name: "colName",
      type: 1,
      enable: 1,
      min: 1,
      max: 100,
    });
    columnTableData.push(lodash.cloneDeep(columnDataItem))
    const types = [{ value: 0, label: 'float' },
    { value: 1, label: 'int', },
    { value: 2, label: 'bool', },]
    const gpsModels = [{ value: 0, label: '无效' },
    { value: 1, label: '手动设置', },
    { value: 2, label: '自动获取', },
    { value: 3, label: '从其他设备获取', },]
    return {
      columnDataItem,
      columnTableData,
      types,
      gpsModels,
    };
  },
  methods: {
    onAddColumn(){
      this.columnTableData.push( lodash.cloneDeep(this.columnDataItem) );
    }
    ,
    onOpened()
    {
      console.log("onOpened into ")
      console.log("companies:",this.companies)
      let grounds = this.$store.getters['models/getGrounds']
      if(grounds.length > 0)
        this.formData.model = grounds[0];
        
      console.log("this.formData.model:",this.formData.model)
      
      if(this.cables.list.length == 0)
        this.$store.dispatch('cables/request', false);
      else
        this.formData.cable = this.cables.list[0];
    },
    getListItemByIndex(list, index) {
      //console.log("getListItemByIndex into list index",list,index)
      let item = {
        id:0,
        label:"NULL",
      }
      if (typeof(list) == "undefined" || typeof(index) == "undefined"){ 
        return item;
      }
      if(list.length < index - 1 ){
        return item;
      }

      return list[index]
    },
    getMapItemById(map, id){
      //console.log("getMapItemById into map:",map)
      let item = {
        id:id,
        name:id,
      }
      if(map && map.size > 0 && map.has(id))
      {
        return map.get(id)
      }
      return item;
    },
    // 重置
    onReset(){
      this.searchInfo.value = {}
      
      this.$store.dispatch("companies/request", false);
      console.log("cables:",this.cables.idMap)
      console.log("companies:",this.companies)
        console.log("idMap:",this.models.idMap)
    },
    // 更新行
    async updateDevDevicesFunc(row) {
      ///device/getById
      // const res = await findDevDevices({ id: row.id })
      console.log("row:", row)
      this.formData.cable = this.getMapItemById(this.cables.idMap, row.cableId)
      if (!this.formData.cable.gndboxs) {
        this.formData.cable.gndboxs = [];
      }

      this.type = 'update'
      this.formData = row
      // this.formData.company = this.$store.getters["companies/getById"](row.companyId);
      // this.formData.maintenance = this.$store.getters["companies/getById"](row.maintenanceId);
      // this.formData.manufacture = this.$store.getters["companies/getById"](row.manufacturerId);
      console.log("this.formData:", this.formData)

      this.dialogFormVisible = true
    }
    ,
    // 弹窗确定
    async enterDialog() {
      console.log("formData:", this.formData)
      this.formData.cableId = this.formData.cable.id;
      this.formData.modelId = this.formData.model.id;
      this.formData.companyId = this.formData.devCompany.id;
      this.formData.maintenanceId = this.formData.maintenance.id;
      this.formData.manufacturerId = this.formData.manufacture.id;
      this.formData.boxId = this.formData.ground.id;

      let postData = lodash.cloneDeep(this.formData)
      delete postData.model;
      delete postData.cable;
      delete postData.maintenance;
      delete postData.manufacture;
      delete postData.createdat;
      delete postData.devCompany;
      delete postData.uuid;
      delete postData.enable;
      delete postData.devCfgs;
      delete postData.manufacture;
      if(this.type != 'update')
      {
        delete postData.id;
      }
      console.log("postData:", postData)

      service({
        url: "/api/v1/devices",
        method: this.type == 'update'?'patch':"post",
        data: postData,
      }).catch((e) => {
        console.log(e);
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: '创建/更改成功'
          })
          this.closeDialog()
          this.getTableData()
        }
      }).catch((e) => {
        console.log(e);
      })

    },
  },
  computed: {
    ...mapState([
      'models', 'cables', 'companies'
    ]),
    ...mapGetters(
      { getModelsArray: 'models/getArray' },
    ),
  },
  mounted() {
    this.getTableData()
  },
  setup() {

    const formData = ref({
      name: 'device-1',
      boxId: 1,
      cableId: 0,
      cable:{},
      ground:{},
      model: {},
      modelId: 0,
      gpsmodel:0,
      longitude: '',
      latitude: '',
      gpsDevID:0,
      length: 0,
      devCompany:{},
      companyId:0,
      maintenance: {},
      maintenanceId:0,
      manufacture: {},
      manufacturerId:0,
      queue: 0,
      serviceLife: 5,
      comment: '',
    })

// =========== 表格控制部分 ===========
    const page = ref(1)
    const total = ref(0)
    const pageSize = ref(15)
    const tableData = ref([])
    const searchInfo = ref({})

// 搜索
    const onSubmit = () => {
      page.value = 1
      pageSize.value = 15
      if (searchInfo.value.deleted === ""){
        searchInfo.value.deleted=null
      }
      if (searchInfo.value.registered === ""){
        searchInfo.value.registered=null
      }
      if (searchInfo.value.unprocessedWarning === ""){
        searchInfo.value.unprocessedWarning=null
      }
      getTableData()
    }

// 分页
    const handleSizeChange = (val) => {
      pageSize.value = val
      getTableData()
    }

// 修改页面容量
    const handleCurrentChange = (val) => {
      page.value = val
      getTableData()
    }

// 查询
    const getTableData = async () => {
      service({
        url: '/api/v1/devices',
        method: "get",
        params: {
          page: page.value,
          pageSize: pageSize.value,
          ext: true,
          type: 1,
          ...searchInfo.value
        },
        paramsSerializer: params => {
          return qs.stringify(params, { indices: false })
        }
      })
          .then((res) => {
            console.log("res:",res)
            if (res.code === 0) {
              if (res.data.list) {
                tableData.value = res.data.list;
                total.value = res.data.total;
                page.value = res.data.page;
                pageSize.value = res.data.pageSize;
              } else {
                tableData.value = res.data;
                total.value = res.data.length;
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
    };


// ============== 表格控制部分结束 ===============
    /*
    // 获取需要的字典 可能为空 按需保留
    const setOptions = async () =>{
    }

    // 获取需要的字典 可能为空 按需保留
    setOptions()

    */
// 多选数据
    const multipleSelection = ref([])
// 多选
    const handleSelectionChange = (val) => {
      console.log("val:",val)
      multipleSelection.value = val
    }

// 删除行
    const deleteRow = (row) => {
      ElMessageBox.confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDevDevicesFunc(row)
      })
    }


// 批量删除控制标记
    const deleteVisible = ref(false)

// 多选删除
    const onDelete = async() => {
      if (multipleSelection.value.length === 0) {
        ElMessage({
          type: 'warning',
          message: '请选择要删除的数据'
        })
        return
      }
      multipleSelection.value &&
      multipleSelection.value.map(item => {
        console.log("item.id:",item.id)
        api.deleteDevice(item.id)
            /*
            service({
                      url: '/api/v1/devices/' + item.id,
                      method: 'delete',
                      data:{id:item.id}
                    })*/
            .then((res) =>
            {
              if (res.code === 0) {
                ElMessage({
                  type: 'success',
                  message: '删除成功'
                })
                if (tableData.value.length === multipleSelection.value.length && page.value > 1) {
                  page.value--
                }
              }
            }).catch((e) => {
          console.log(e);
        });
      })

      deleteVisible.value = false
      getTableData()
    }


// 行为控制标记（弹窗内部需要增还是改）
    const type = ref('')

// 删除行
    const deleteDevDevicesFunc = async (row) => {
      service({
        url: '/api/v1/devices/' + row.id,
        method: 'delete',
        data: { id: row.id }
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: '删除成功'
          })

          if (tableData.value.length === 1 && page.value > 1) {
            page.value--
          }
          getTableData();
        }
      }).catch((e) => {
        console.log(e);
      });
    }

// 弹窗控制标记
    const dialogFormVisible = ref(false)

// 打开弹窗
    const openDialog = () => {
      type.value = 'create'

      formData.value.name = "device-" + (tableData.value.length + 1)
      console.log("tableData.value.length",tableData.value.length)
      console.log("formData.value.name",formData.value.name)
      console.log(" formData.modelId:", formData.value.modelId)


      dialogFormVisible.value = true
    }

// 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false
    }

    return {
      formData,
      page,
      total,
      pageSize,
      tableData,
      searchInfo,
      onSubmit,
      handleSizeChange,
      handleCurrentChange,
      getTableData,
      multipleSelection,
      handleSelectionChange,
      deleteRow,
      deleteVisible,
      onDelete,
      type,
      deleteDevDevicesFunc,
      dialogFormVisible,
      openDialog,
      closeDialog,
    }
  }
}
</script>
