<template>
  <el-card style="width: 95%">
    <div v-if="!!device.id" class="device-data-header">
      {{ getCableName(cable) }}
      &nbsp; / &nbsp;
      <div class="device-data-header-item">
        <box-icon :id="device.boxId"/>
        {{ box.name }}
      </div>
      &nbsp; / &nbsp;
      <div class="device-data-header-item">
        <device-icon :id="device.id"/>
        {{ device.name }}
      </div>
      <el-button @click="jump" style="border: none" icon="InfoFilled">{{ t('deviceManage.buttonJumpToDeviceCenter') }}</el-button>
    </div>
    <el-descriptions :column="1" border>
      <template v-for="value in values.devCfgs" :key="value.dataUnit.name">
        <el-descriptions-item v-if="value.enable" :label="store.state.user.lang === 'zh' ? value.dataUnit.name : value.dataUnit.keyword">
          {{ value.dataValue }} {{ value.dataUnit.unit }}
        </el-descriptions-item>
      </template>
    </el-descriptions>
  </el-card>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUpdated,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import { getCableName } from '@/utils/cableNameGen'
import { useI18n } from "vue-i18n";

const { t } = useI18n()

const props = defineProps(['deviceId'])
const store = useStore()
const router = useRouter()

const device = ref({})
const box = ref({})
const cable = ref({})
const values = ref({})

function load() {
  device.value = store.getters["cables/getDeviceById"](props.deviceId)
  box.value = store.getters["cables/getDeviceById"](device.value.boxId)
  cable.value = store.getters["cables/getById"](box.value.cableId)
  values.value = store.getters["cables/getDeviceValueById"](device.value.id)
}

function jump() {
  if (box.value.id === box.value.name || !cable.value.id) {
    return
  }
  store.commit('cables/setCurrentDevice', device.value.id)
  router.push('../devicecenter')
}

onMounted(load)

onUpdated(load)

</script>

<style scoped>
.device-data-header {
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.device-data-header-item {
  display: flex;
  align-items: center;
}
</style>