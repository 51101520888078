<template>
  <div v-loading="loading">
    <h2>当前设备：{{ props.device.id }} {{ props.device.name }}</h2>
    <h2>当前所选日期：{{ dateSelected.getFullYear() }}年 {{ dateSelected.getMonth() + 1 }}月 {{ dateSelected.getDate() }}日</h2>
    <h3>当月 headend 数据总条数：{{ headendMonthlyCount }} | dataf 数据总条数：{{ datafMonthlyCount }}</h3>
    <br/>
    <el-button @click="getMonthlyData" type="primary">查询当月数据</el-button>
    <el-button :disabled="monthDisable()" @click="compareTheDay" type="success">查询当天数据</el-button>
    <!--    <el-button @click="getEarliest">查询最早</el-button>-->
<!--    <el-button @click="getAnchor">查找锚</el-button>-->
<!--    <span>锚：{{ Math.floor(anchor / 60) }} 分 {{ anchor % 60 }} 秒</span>-->
    <el-calendar v-model="dateSelected">
      <template #dateCell="{ data }">
        <div :class="getCellClass(data)">
          <p>{{ data.date.getDate() }}</p>
          <p>h: {{ getHeadendData(data) }}</p>
          <p>d: {{ getDatafData(data) }}</p>
        </div>
      </template>
    </el-calendar>
    <el-drawer v-model="drawerVisible" :size="1050">
      <div style="display: flex; flex-direction: row; height: 900px">
        <div><h2>headend: {{ headendTableData.length }} 条</h2>
          <el-table :data="headendTableData" style="width: 500px" max-height="900" v-loading="headendLoading">
            <el-table-column type="expand">
              <template #default="{row}">
            <pre>
              {{ headendFormatter(row) }}
            </pre>
              </template>
            </el-table-column>
            <el-table-column label="time" prop="time" :formatter="timeFormatter"></el-table-column>
            <!--            <el-table-column label="操作">-->
            <!--              <template #default="{row}">-->
            <!--                <el-button-->
            <!--                    v-show="props.device.model.dataFmt.abbreviation === 'ngbemd' ? !writeDisableForNgbemd(row) : !writeDisable(row)"-->
            <!--                    @click="write(row)">写入dataf-->
            <!--                </el-button>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </div>
        <div><h2>dataf: {{ datafTableData.length }} 条</h2>
          <el-table :data="datafTableData" style="width: 500px" max-height="900" v-loading="datafLoading">
            <el-table-column type="expand">
              <template #default="{row}">
            <pre>
              {{ datafFormatter(row) }}
            </pre>
              </template>
            </el-table-column>
            <el-table-column label="time" prop="time" :formatter="timeFormatter"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <el-popconfirm
        width="220"
        confirm-button-text="开始"
        cancel-button-text="取消"
        :icon="InfoFilled"
        icon-color="#626AEF"
        title="确定开始任务吗? 任务开始后请勿关闭或刷新浏览器"
        @confirm="taskType > 0 ? (taskType > 1 ? fillAll() : fillTheMonth()) : fillTheDay()"
    >
      <template #reference>
        <div>
          <el-button :disabled="monthDisable() || writeMonthDisable()" @click="taskType = 1" type="primary">补全当月数据
          </el-button>
          <el-button :disabled="writeDayDisable() || monthDisable()" @click="taskType = 0" type="success">补全当天数据
          </el-button>
          <el-button @click="taskType = 2" type="danger">补全所选日期前的所有数据</el-button>
        </div>
      </template>
    </el-popconfirm>
  </div>
  <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-model="dialogVisible"
  >
    <h2 v-if="earliestTimeStamp>0">最早的数据时间：{{ new Date(earliestTimeStamp * 1000).Format('yyyy-MM-dd hh:mm:ss') }}</h2>
    <h2>当天的真实 headend 数据条数: {{ headendTableData.length }} </h2>
    <br/>
    <el-progress
        :text-inside="true"
        :stroke-width="24"
        :percentage="donePercent"
        status="success"
    />
    <br/>
    <h3>总任务数：{{ allTasks }}</h3>
    <h3>成功任务数：{{ goodTasks }}</h3>
    <h3>失败任务数：{{ badTasks }}</h3>
    <h1 v-show="taskButtonDisable()">任务正在进行中，请勿关闭或刷新浏览器！</h1>
    <br/>
    <div>
      <!--      <el-button :disabled="taskButtonDisable()" @click="multipleBatches ? startMultiple : startTask" type="danger">-->
      <!--        开始任务-->
      <!--      </el-button>-->
      <el-button :disabled="taskButtonDisable()" @click="cancelTask" type="info">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import {ref, computed, defineEmits} from 'vue'
import axios from "axios"
import {getCount, newWrite, postValue} from '@/api/migrate.js'
import {getValues} from "@/api/values";

const emits = defineEmits(["job-done"])

const loading = ref(false)

const props = defineProps({
  device: {
    type: Object,
    default() {
      return {}
    },
  },
})

let id = -1
let currentYear = 2023
let currentMonth = 3
const headendData = ref([0])
const datafData = ref([0])

const headendMonthlyCount = computed(() => headendData.value.reduce((prev, curr) => prev + curr))
const datafMonthlyCount = computed(() => datafData.value.reduce((prev, curr) => prev + curr))

const headUrl = 'https://www.headend.store/influxdb/query'
const headDb = 'pmserv'
const headTable = 'pmiu'

const dateSelected = ref(new Date())

function getHeadendData(data) {
  if (props.device.id !== id || data.date.getFullYear() !== currentYear || data.date.getMonth() !== currentMonth - 1) {
    return
  }
  if (data.type !== 'current-month') {
    return
  }
  return headendData.value[data.date.getDate() - 1]
}

function getDatafData(data) {
  if (props.device.id !== id || data.date.getFullYear() !== currentYear || data.date.getMonth() !== currentMonth - 1) {
    return
  }
  if (data.type !== 'current-month') {
    return
  }
  return datafData.value[data.date.getDate() - 1]
}

function getCellClass(data) {
  if (props.device.id !== id || data.date.getFullYear() !== currentYear || data.date.getMonth() !== currentMonth - 1) {
    return
  }
  if (data.type !== 'current-month') {
    return
  }
  if (datafData.value[data.date.getDate() - 1] === headendData.value[data.date.getDate() - 1]) {
    return
  } else if (props.device.model.dataFmt.abbreviation === 'ngbemd') {
    if (datafData.value[data.date.getDate() - 1] === 12 && headendData.value[data.date.getDate() - 1] === 288) {
      return
    } else {
      return 'writable'
    }
  } else {
    return 'writable'
  }
}

function getDaysOfMonth(year, month) {
  // https://natclark.com/tutorials/javascript-days-in-month/
  // 正常情况下，第二个参数应该是 (月 - 1)，第三个参数是从 1 开始的
  // 但第三个参数为 0 就会得到上个月的最后一天
  return new Date(year, month, 0).getDate()
}

function getMonthlyData() {
  id = props.device.id
  datafData.value = [0]
  headendData.value = [0]
  let year = dateSelected.value.getFullYear(),
      month = dateSelected.value.getMonth() + 1,
      lastDayOfMonth = getDaysOfMonth(year, month)
  currentYear = year
  currentMonth = month
  let day = 1
  let headWaits = []
  let datafWaits = []
  while (day <= lastDayOfMonth) {
    headWaits.push(getDailyFromHeadend(year, month, day))
    datafWaits.push(getDailyFromDataf(year, month, day))
    day += 1
  }
  if (headWaits.length > 0) {
    loading.value = true
    let oneLoaded = false
    Promise.all(headWaits).then(r => {
      headendData.value = r
      if (!oneLoaded) {
        oneLoaded = true
        refreshDate()
      } else {
        loading.value = false
      }
    })
    Promise.all(datafWaits).then(r => {
      datafData.value = r
      if (!oneLoaded) {
        oneLoaded = true
        refreshDate()
      } else {
        loading.value = false
      }
    })
  }
}

function refreshDate() {
  let year = dateSelected.value.getFullYear(),
      monthIndex = dateSelected.value.getMonth(),
      day = dateSelected.value.getDate(),
      lastDay = getDaysOfMonth(year, monthIndex + 1)
  if (day < lastDay) {
    day += 1
  } else {
    day -= 1
  }
  dateSelected.value = new Date(year, monthIndex, day)
}

function getDailyFromHeadend(year, month, day) {
  let [fromStr, toStr] = getFromAndToDateStr(year, month, day)
  let q = `SELECT COUNT(*) from ${headTable} WHERE uid='${props.device.deviceCode}' AND time >= '${fromStr}' AND time <= '${toStr}'`
  return axios({
    method: "get",
    url: headUrl,
    params: {
      db: headDb,
      q,
    },
  }).then(r => {
    if (r.status === 200) {
      let series = r.data?.results?.[0]?.series?.[0]
      if (series && series.columns) {
        let maxV = 0
        for (let v of series.values[0]) {
          if (v && v > maxV) {
            maxV = v
          }
        }
        return maxV
      } else {
        return 0
      }
    }
  })
}

function getDailyFromDataf(year, month, day) {
  let [fromStr, toStr] = getFromAndToDateStr(year, month, day)
  return getCount(props.device.id, props.device.model?.dataFmt?.abbreviation, fromStr, toStr)
      .then(r => {
        let series = r.data?.[0]?.Series?.[0]
        if (series && series.columns) {
          let timeIndex = series.columns.indexOf("time")
          let maxV = 0
          series.values[0].forEach((v, i) => {
            if (i === timeIndex) {
              return
            } else {
              if (v && v > maxV) {
                maxV = v
              }
            }
          })
          return maxV
        } else {
          return 0
        }
      })
}

function getFromAndToDateStr(year, month, day) {
  const monthStr = month.toString().padStart(2, '0')
  const dayStr = day.toString().padStart(2, '0')
  let fromStr = `${year}-${monthStr}-${dayStr}T00:00:00Z`,
      toStr = `${year}-${monthStr}-${dayStr}T23:59:59Z`
  return [fromStr, toStr]
}

const headendTableData = ref([])
const datafTableData = ref([])
const headendLoading = ref(false)
const datafLoading = ref(false)
const drawerVisible = ref(false)

let headendColumns = []
let datafColumns = []

function compareTheDay() {
  if (anchor.value > 0) {
    headendTableData.value = []
    datafTableData.value = []
    drawerVisible.value = true
    getDailyData()
  } else {
    getAnchor().then(() => {
      headendTableData.value = []
      datafTableData.value = []
      drawerVisible.value = true
      getDailyData()
    })
  }
}

function getDailyData() {
  let year = dateSelected.value.getFullYear(),
      month = dateSelected.value.getMonth() + 1,
      day = dateSelected.value.getDate()
  console.log(">>>, getting", month, day)
  return Promise.all([
    getTheDayFromHeadend(year, month, day),
    getTheDayFromDataf(year, month, day),
  ])
}

function getTheDayFromHeadend(year, month, day) {
  headendLoading.value = true
  let [fromStr, toStr] = getFromAndToDateStr(year, month, day)
  let q = `SELECT * from ${headTable} WHERE uid='${props.device.deviceCode}' AND time >= '${fromStr}' AND time <= '${toStr}'`
  return axios({
    method: "get",
    url: headUrl,
    params: {
      db: headDb,
      q,
    },
  }).then(r => {
    if (r.status === 200) {
      let series = r.data?.results?.[0]?.series?.[0]
      if (series && series.columns) {
        headendColumns = series.columns
        let data = []
        let timeIndex = series.columns.indexOf("time")
        for (let v of series.values) {
          let time = Math.floor(Date.parse(v[timeIndex]) / 1000)
          data.push({
            time,
            headend: v
          })
        }
        headendTableData.value = data
      }
      headendLoading.value = false
    }
  })
}

function getTheDayFromDataf(year, month, day) {
  datafLoading.value = true
  let [fromStr, toStr] = getFromAndToDateStr(year, month, day)
  let starttime = Math.floor(Date.parse(fromStr) / 1000)
  let endtime = Math.floor(Date.parse(toStr) / 1000)

  return getValues({
    id: props.device.id,
    starttime,
    endtime,
  })
      .then(r => {
        let series = r.data?.[0]?.Series?.[0]
        if (series && series.columns) {
          datafColumns = series.columns
          let data = []
          let timeIndex = series.columns.indexOf("time")
          for (let v of series.values) {
            // let time = parseInt(Date.parse(v[timeIndex]) / 1000)
            data.push({
              time: v[timeIndex],
              dataf: v
            })
          }
          datafTableData.value = data
        }
        datafLoading.value = false
      })
}

function timeFormatter(row) {
  return new Date(row.time * 1000).toLocaleString()
}

function headendFormatter(row) {
  let data = {}
  if (!row.headend) {
    return '-'
  }
  for (let i = 0; i < row.headend.length; i++) {
    data[headendColumns[i]] = row.headend[i]
  }
  return JSON.stringify(data, null, 2)
}

function datafFormatter(row) {
  let data = {}
  if (!row.dataf) {
    return '-'
  }
  for (let i = 0; i <= row.dataf.length; i++) {
    data[datafColumns[i]] = row.dataf[i]
  }
  return JSON.stringify(data, null, 2)
}

// 写入

const ngcmd = {
  // online: false,
  imei: props.device.userId,
  // rssi: 0,
  // batvol: 0,
  // gpio: 0,
  // temp: 0,
  // humi: 0,
  tempa: 0,
  tempb: 0,
  tempc: 0,
  curra: 0,
  currb: 0,
  currc: 0,
  gcurra: 0,
  gcurrb: 0,
  gcurrc: 0,
  gvola: 0,
  gvolb: 0,
  gvolc: 0,
  // timestamp: 0,
}

// 从后端的 toMap 抄来的
const ngcmdMap = {
  "tempA": "tempa",
  "tempB": "tempb",
  "tempC": "tempc",
  "runCurA": "curra",
  "runCurB": "currb",
  "runCurC": "currc",
  "earthCurA": "gcurra",
  "earthCurB": "gcurrb",
  "earthCurC": "gcurrc",
  "earthVolA": "gvola",
  "earthVolB": "gvolb",
  "earthVolC": "gvolc",
}

const ngbemd = {
  simRssi: 0,
  batVol: 0,
  imei: props.device.userId
}

const ngbemdMap = {
  net_rssi: "simRssi",
  box_batvol: "batVol"
}

function writeDisable(row, distance) {
  if (!distance) {
    distance = 10
  }
  for (let datafRow of datafTableData.value) {
    if (Math.abs(datafRow.time - row.time) < distance) {
      return true
    }
  }
}

function ngbemdPrep(row) {
  let value = {...ngbemd}
  // 避免 partial write
  let badData = true
  for (let i = 0; i < headendColumns.length; i++) {
    let colName = headendColumns[i]
    if (ngbemdMap[colName]) {
      let v = row.headend[i]
      // 如果有缺失项，填入 0，并
      if (!v) {
        v = 0
      } else {
        // 只要有真实数据，就取消标记
        badData = false
      }
      value[ngbemdMap[colName]] = row.headend[i]
    }
  }
  // 如果是坏数据，返回空
  if (badData) {
    return {}
  }
  return value
}

function ngcmdPrep(row) {
  let value = {...ngcmd}
  let newValue = {}
  for (let i = 0; i < headendColumns.length; i++) {
    let colName = headendColumns[i]
    if (colName.indexOf("_") > -1) {
      colName = colName.split("_").pop()
    }
    if (ngcmd[colName] !== undefined) {
      value[colName] = row.headend[i]
      if (colName === 'online') {
        value[colName] = !!row.headend[i]
      }
    }
  }
  // 转成后端那两个 struct 的 toMap 以后的值
  for (let [k, v] of Object.entries(ngcmdMap)) {
    if (value[v] !== undefined && value[v] !== null) {
      newValue[k] = value[v]
    }
  }
  return newValue
}

function write(row) {
  // 根据 datafmt 判断写哪些列
  let value = {}
  if (props.device.model.dataFmt.abbreviation === 'ngcmd') {  // 环流监测
    value = ngcmdPrep(row)
  } else if (props.device.model.dataFmt.abbreviation === 'ngbemd') {  // 边缘代理
    value = ngbemdPrep(row)
  }
  if (JSON.stringify(value) === '{}') {
    return Promise.resolve()
  }
  // let value = {...ngcmd}
  // for (let i = 0; i < headendColumns.length; i++) {
  //   let colName = headendColumns[i]
  //   if (colName.indexOf("_") > -1) {
  //     colName = colName.split("_").pop()
  //   }
  //   if (ngcmd[colName] !== undefined) {
  //     value[colName] = row.headend[i]
  //     if (colName === 'online') {
  //       value[colName] = !!row.headend[i]
  //     }
  //   }
  // }
  return postValue(row.time, value).then(r => {
    if (r.code === 0) {
      if (drawerVisible.value) {  // 打开 innerDrawer 时, 也就是单条数据写入时
        getDailyData()
      }
      goodTasks.value += 1
      return true
    } else {
      badTasks.value += 1
    }
  })
}

// 一些 disabler

function monthDisable() {
  return headendData.value.length === 0 ||
      props.device.id !== id || dateSelected.value.getFullYear() !== currentYear || dateSelected.value.getMonth() !== currentMonth - 1
}

function writeMonthDisable() {
  return headendMonthlyCount.value === datafMonthlyCount.value
}

function writeDayDisable() {
  return headendData.value[dateSelected.value.getDate() - 1] === datafData.value[dateSelected.value.getDate() - 1]
}

function taskButtonDisable() {
  return (goodTasks.value + badTasks.value !== 0) && ((goodTasks.value + badTasks.value) < allTasks.value)
}

// 批量写入

// const multipleBatches = ref(false)

const allTasks = ref(0)
const goodTasks = ref(0)
const badTasks = ref(0)
const donePercent = computed(() => {
  if (allTasks.value === 0) {
    return 0
  }
  console.log("good, bad, all, percent", goodTasks.value, badTasks.value, allTasks.value, Math.floor((goodTasks.value + badTasks.value) / allTasks.value * 100))
  return Math.floor((goodTasks.value + badTasks.value) / allTasks.value * 100)
})
const dialogVisible = ref(false)
let waits = []

function startTask() {
  console.log("task started, ", waits)
  if (waits.length < 1) {
    console.log("done")
    return Promise.resolve()
  }
  // 新的写
  allTasks.value = waits.length
  // 准备 values
  let prepFunc = ngcmdPrep
  if (props.device.model.dataFmt.abbreviation === 'ngbemd') {
    prepFunc = ngbemdPrep
  }
  let values = waits.reduce((prev, curr) => {
    let data = prepFunc(curr)
    if (JSON.stringify(data) === '{}') {
      return []
    } else {
      return [...prev, {
        time: curr.time,
        data,
      }]
    }
  }, [])
  if (values.length === 0) {
    return Promise.resolve()
  }
  return newWrite({
    id: props.device.id,
    values,
  }).then((r) => {
    if (r.code === 0 && r.data) {
      goodTasks.value = r.data.success
      badTasks.value = goodTasks.value - goodTasks.value
    }
  })

  // 老的写。好像同一个 ip 地址只能建立 6 个 tcp 连接，所以太慢。
  // return asyncWaits(waits, 0, 100).then((r) => {
  //   console.log("done", r)
  //   waits = []
  // })
}

// deprecated
function getWaitsForNgbemd() {
  let waits = []
  let hour = 0  // 排除重复
  for (let row of headendTableData.value) {
    if (!writeDisableForNgbemd(row)) {
      let rowHour = Math.floor(row.time / 3600)
      if (hour !== rowHour) {
        hour = rowHour
        waits.push(row)
      } else if (
          distanceToAnchor(row.time) < distanceToAnchor(waits.slice(waits.length - 1)[0].time)
      ) {
        waits.pop()
        waits.push(row)
      }
    }
  }
  return waits
}

function distanceToAnchor(time) {
  let date = new Date(time * 1000)
  return Math.abs((date.getMinutes() * 60 + date.getSeconds()) - anchor.value)
}

// deprecated
function writeDisableForNgbemd(row) {
  let rowDate = new Date(row.time * 1000)
  if (
      (!writeDisable(row, 60 * 60 * 2 - 10)) &&
      (rowDate.getHours() % 2 === 0) &&
      (Math.abs((rowDate.getMinutes() * 60 + rowDate.getSeconds()) - anchor.value) < 300)
  ) {
    return false
  } else {
    return true
  }
}

function timeTableReducer(prev, curr, i) {
  let hour = new Date(curr.time * 1000).getHours()
  if (prev[hour] === undefined) {
    prev[hour] = []
  }
  prev[hour].push(i)
  return prev
}

function fillTheDayForNgbemd() {
  return getAnchor().then(() => {
    return getDailyData().then(() => {
      if (datafTableData.value.length < 1) {
        waits = getWaitsForNgbemd()
        console.log("? <1", waits)
        allTasks.value = waits.length
        return startTask()
      } else {
        let datafTimeTable = datafTableData.value.reduce(timeTableReducer, {}),
            headendTimeTable = headendTableData.value.reduce(timeTableReducer, {})
        // 是奇还是偶
        let oddCount = 0,
            evenCount = 0
        for (let key of Object.keys(datafTimeTable)) {
          if (key % 2 === 0) {
            evenCount += 1
          } else {
            oddCount += 1
          }
        }
        let isOdd = false
        if (oddCount > evenCount) {
          isOdd = true
        }
        let i = 0
        if (isOdd) {
          i = 1
        }
        // 检查每两个小时
        let l = []
        for (i; i <= 23; i += 2) {
          if (datafTimeTable[i] !== undefined) {
            continue
          }
          let buffer = -1
          for (let headTableIndex of headendTimeTable[i]) {
            if (buffer < 0) {
              buffer = headTableIndex
              continue
            }
            if (distanceToAnchor(headendTableData.value[headTableIndex].time) <
                distanceToAnchor(headendTableData.value[buffer].time)) {
              buffer = headTableIndex
            }
          }
          l.push(headendTableData.value[buffer])
        }
        waits = l
        allTasks.value = waits.length
        return startTask()
      }
      }
    )
  })
}


function fillTheDay() {
  // multipleBatches.value = false
  dialogVisible.value = true
  allTasks.value = 0
  goodTasks.value = 0
  badTasks.value = 0
  waits = []
  // if (props.device.model.dataFmt.abbreviation === 'ngbemd') {
  //   return fillTheDayForNgbemd()
  // }
  return getDailyData().then(() => {
        for (let row of headendTableData.value) {
          if (!writeDisable(row)) {
            waits.push(row)
          }
        }
        allTasks.value = waits.length
        return startTask()
      }
  )
}

function asyncWaits(waits, offset, step) {
  if (offset >= waits.length) {
    return Promise.resolve()
  }
  if (!step) {
    step = 10
  }
  let endIndex = offset + step
  if (endIndex > waits.length) {
    endIndex = waits.length
  }
  return Promise.all(
      waits.slice(offset, endIndex).map((val) => write(val))
  ).then(() => {
    return asyncWaits(waits, offset + step, step)
  })
}

function cancelTask() {
  waits = []
  dialogVisible.value = false
  getMonthlyData()
}

// 更多的批量写入

let taskType = 0

function fillTheMonth() {
  // multipleBatches.value = true
  dialogVisible.value = true
  allTasks.value = 0
  goodTasks.value = 0
  badTasks.value = 0
  waits = []
  let year = dateSelected.value.getFullYear(),
      month = dateSelected.value.getMonth() + 1,
      day = 1,
      lastDayOfMonth = getDaysOfMonth(year, month)
  return fillDayInMonth(year, month, day, lastDayOfMonth)
  // let data = []
  // dateSelected.value = new Date(year, month, day)
  // fillTheDay()
  // console.log("???", data)
}

function fillDayInMonth(year, month, day, lastDay) {
  console.log("filling,", year, month, day)
  if (day > lastDay) {
    return Promise.resolve()
  }
  dateSelected.value = new Date(year, month - 1, day)
  return fillTheDay().then(() => {
    console.log("?filled", year, month, day)
    fillDayInMonth(year, month, day + 1, lastDay)
    // startTask().then(() => {
    //   console.log("filled", year, month, day)
    //   fillDayInMonth(year, month, day + 1, lastDay)
    // })
  })
}

const earliestTimeStamp = ref(0)

function getEarliest() {
  let q = `SELECT * from ${headTable} WHERE uid='${props.device.deviceCode}' ORDER BY time ASC LIMIT 1`
  return axios({
    method: "get",
    url: headUrl,
    params: {
      db: headDb,
      q,
    },
  }).then(r => {
    if (r.status === 200) {
      let series = r.data?.results?.[0]?.series?.[0]
      if (series && series.columns) {
        let timeIndex = series.columns.indexOf("time"),
            timeStr = series.values[0][timeIndex]
        earliestTimeStamp.value = Math.floor(Date.parse(timeStr) / 1000)
      }
    }
  })
}

function fillAll() {
  dialogVisible.value = true
  allTasks.value = 0
  goodTasks.value = 0
  badTasks.value = 0
  waits = []
  let year = dateSelected.value.getFullYear(),
      month = dateSelected.value.getMonth() + 1,
      day = dateSelected.value.getDate()
  getEarliest().then(() => {
    fillEachMonth(year, month, day)
  })
}

function fillEachMonth(year, month, day) {
  // console.log('filling month', year, month)
  if (earliestTimeStamp.value === 0) {
    return Promise.resolve()
  }
  // 改变时间
  dateSelected.value = new Date(year, month - 1, day)
  let fillingDateStr = `${dateSelected.value.getFullYear()}, ${dateSelected.value.getMonth() + 1}, ${dateSelected.value.getDate()}`
  console.log('filling: ', fillingDateStr)
  // 边界检查
  console.log("timestamp", Math.floor(dateSelected.value.getTime() / 1000), earliestTimeStamp.value)
  if (Math.floor(dateSelected.value.getTime() / 1000) < earliestTimeStamp.value) {
    console.log("all done")
    emits("job-done")
    return Promise.resolve()
  }
  return fillTheDay().then(() => {
    console.log("?filled: ", fillingDateStr)
    fillEachMonth(year, month, day - 1)
  })
}

// 写 ngbemd

const anchor = ref(-1)

function getAnchor() {
  const now = dateSelected.value.getTime()
  return searchAnchor(now)
}

const dayInMs = 24 * 60 * 60 * 1000

function searchAnchor(now, dayChange) {
  let year = dateSelected.value.getFullYear(),
      month = dateSelected.value.getMonth() + 1,
      day = dateSelected.value.getDate()
  if (!dayChange || dayChange === 0) {
    dayChange = -1
  }
  return getDailyFromDataf(year, month, day)
      .then(r => {
        if (r > 0) {
          return getTheDayFromDataf(year, month, day)
              .then(() => {
                let anchorDate = new Date(datafTableData.value[0].time * 1000)
                anchor.value = anchorDate.getMinutes() * 60 + anchorDate.getSeconds()
                // 找到锚，恢复日期
                dateSelected.value = new Date(now)
              })
        } else {
          let current = dateSelected.value.getTime()
          let distance = Math.abs(current - now)
          if (dayChange > 0) {
            dayChange = -distance
          } else {
            dayChange = distance + dayInMs
          }
          dateSelected.value = new Date(now + dayChange)
          return searchAnchor(now, dayChange)
        }
      })
}

// 自动写




</script>

<style scoped>
.writable {
  font-size: 13px;
  color: red;
  font-weight: bold;
}
</style>