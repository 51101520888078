import service from '@/utils/request'

const url = '/api/v1/migrate/'

export const getCount = (id, datafmt, from, to) => {
  return service({
    url,
    method: 'get',
    params: {
      id,
      datafmt,
      from,
      to,
      count: true,
    }
  })
}

export const postValue = (time, data) => {
  return service({
    url: "back/api/v1/pmiuvalue",
    method: "post",
    data,
    params: {
      time,
    }
  })
}

export const postInfo = (time, data) => {
  return service({
    url: "back/api/v1/pmiuinfo",
    method: "post",
    data,
    params: {
      time,
    }
  })
}

export const newWrite = (data) => {
  return service({
    url,
    method: "post",
    data
  })
}
