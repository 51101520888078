<template>
  <el-button type="success" plain style="position: absolute; right: 13px; top: 4px;" icon="download"
             @click="exportDevices">{{ t('deviceManage.buttonExport') }}
  </el-button>

  <div>
    <div class="gva-search-box search-box">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start" @keyup.enter="onSubmit">
        <el-form-item :label="t('deviceManage.searchCable')" label-width="50px">
          <el-select v-model="searchInfo.cable" filterable>
            <el-option v-for="c in $store.state.cables.list" :key="c.id" :label="getCableName(c)" :value="c.id"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="$store.state.user.userInfo.id===1" :label="t('deviceManage.searchCompany')" label-width="90px">
          <el-select v-model="searchInfo.userCompany" filterable>
            <el-option v-for="c in $store.state.companies.list" :key="c.id" :label="c.name" :value="c.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="t('deviceManage.searchUserId')" label-width="90px">
          <el-input v-model.trim="searchInfo.userId"/>
        </el-form-item>
        <el-form-item :label="t('deviceManage.searchDeviceCode')" label-width="110px">
          <el-input v-model.trim="searchInfo.deviceCode"/>
        </el-form-item>
        <el-form-item label-width="50px">
          <el-button size="small" type="primary" icon="search" @click="onSubmit">{{
              t('deviceManage.searchSubmit')
            }}
          </el-button>
          <el-button size="small" icon="refresh" @click="onReset">{{ t('deviceManage.searchReset') }}</el-button>
        </el-form-item>
      </div>
    </div>
    <div class="gva-table-box box">
      <div class="gva-btn-list" style="width: 100%">
        <el-button size="small" type="primary" icon="plus" @click="openDialog">{{
            t('deviceManage.formButtonNew')
          }}
        </el-button>
        <el-popover v-model:visible="deleteVisible" placement="top" width="160">
          <p>{{ t('deviceManage.deleteConfirmQuestion') }}</p>
          <div style="text-align: right; margin-top: 8px;">
            <el-button size="small" text @click="deleteVisible = false">{{ t('deviceManage.cancel') }}</el-button>
            <el-button size="small" type="primary" @click="onDelete">{{ t('deviceManage.confirm') }}</el-button>
          </div>
          <template #reference>
            <el-button icon="delete" size="small" style="margin-left: 10px;" @click="deleteVisible=true"
                       :disabled="!multipleSelection.length">{{ t('deviceManage.formButtonDelete') }}
            </el-button>
          </template>
        </el-popover>
        <el-popconfirm :title="t('deviceManage.enableConfirmQuestion')" @confirm="handleBatchEnable">
          <template #reference>
            <el-button size="small" :disabled="multipleSelection.length < 1">{{
                t('deviceManage.formButtonEnable')
              }}
            </el-button>
          </template>
        </el-popconfirm>
        <el-popconfirm :title="t('deviceManage.disableConfirmQuestion')" @confirm="handleBatchDisable">
          <template #reference>
            <el-button size="small" :disabled="multipleSelection.length < 1">{{
                t('deviceManage.formButtonDisable')
              }}
            </el-button>
          </template>
        </el-popconfirm>
      </div>
      <el-table ref="multipleTable" style="width: 100%" tooltip-effect="dark" :data="tableData" row-key="id"
                @selection-change="handleSelectionChange" height="700">
        <el-table-column type="selection" min-width="30"/>
        <el-table-column align="left" label="ID" prop="id" min-width="50"/>
        <el-table-column align="left" :label="t('deviceManage.formDeviceName')" min-width="100">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <device-icon :id="scope.row.id" size="petit"/>
              <el-link @click="showDeviceData(scope.row.id)">{{ scope.row.name }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formDeviceCode')" prop="deviceCode" min-width="100"/>
        <el-table-column align="left" :label="t('deviceManage.formDeviceModel')" min-width="100">
          <template #default="{ row }">
            {{ this.t('dbDeviceModels.' + row.model.name) }}
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formCable')" min-width="120">
          <template #default="scope">
            {{
              getCableName(
                  $store.getters['cables/getById'](
                      $store.getters['cables/getDeviceById'](scope.row.boxId).cableId
                  ))
            }}
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formBox')" min-width="100">
          <template #default="scope">
            {{ $store.getters['cables/getDeviceById'](scope.row.boxId).name }}
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formCompany')" min-width="100">
          <template #default="scope">
            {{ $store.getters['companies/getById'](scope.row.companyId).name }}
          </template>
        </el-table-column>

        <el-table-column type="expand" :label="t('deviceManage.expand')" width="80">
          <template #default="scope">
            <el-row>
              <el-col :span="1"/>
              <el-col :span="5">{{ t('deviceManage.formUserId') }}: {{ scope.row.userId }}</el-col>
              <el-col :span="5">{{ t('deviceManage.formDeviceCode') }}: {{ scope.row.deviceCode }}</el-col>
              <el-col :span="5">{{ t('deviceManage.formGpsModel') }}:
                {{ $t('dbGpsModels.' + $store.getters['models/getGpsModelByIndex'](scope.row.gpsmodel).name) }}
              </el-col>
            </el-row>
            <el-row v-if="scope.row.gpsmodel==1">
              <el-col :span="1"/>
              <el-col :span="5">{{ t('deviceManage.formLongitude') }}: {{ scope.row.longitude }}</el-col>
              <el-col :span="5">{{ t('deviceManage.formLatitude') }}: {{ scope.row.latitude }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="5">{{ t('deviceManage.formMaintenance') }}:
                {{ $store.getters['companies/getById'](scope.row.maintenanceId).name }}
              </el-col>
              <el-col :span="5">{{ t('deviceManage.formManufacture') }}:
                {{ $store.getters['companies/getById'](scope.row.manufacturerId).name }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="5">{{ t('deviceManage.formOperationAt') }}: {{
                  formatDate(scope.row.operationat)
                }}
              </el-col>
              <el-col :span="5">{{ t('deviceManage.formServiceLife') }}: {{ scope.row.service_life }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="8">{{ t('deviceManage.formCreatedAt') }}: {{ formatDate(scope.row.createdat) }}</el-col>
              <el-col :span="6">UUID: {{ scope.row.uuid }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="12">{{ t('deviceManage.formComment') }}: {{ scope.row.comment }}</el-col>
              <!--                <el-button v-if="this.$store.state.user.userInfo.id===1"-->
              <!--                           @click="migrateVisible = true; migratingDevice=scope.row">-->
              <!--                  从headend迁移数据-->
              <!--                </el-button>-->
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.enable')" prop="enable" width="80">
          <template #default="scope">
            <el-switch v-model="scope.row.enable" size="small" active-color="#13ce66" inactive-color="#C0CCDA"
                       @click="enableDevDevicesFunc(scope.row.id, scope.row.enable)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.operations')" min-width="120">
          <template #default="scope">
            <el-button plain type="primary" icon="edit" size="small" class="table-button"
                       @click="updateDevDevicesFunc(scope.row)">{{ t('deviceManage.edit') }}
            </el-button>
            <el-button plain type="danger" icon="delete" size="small" @click="deleteRow(scope.row)">
              {{ t('deviceManage.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="gva-pagination pager">
        <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page="page" :page-size="pageSize"
                       :page-sizes="[15, 30, 50, 100]" :total="total" @current-change="handleCurrentChange"
                       @size-change="handleSizeChange"/>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible"
               :before-close="closeDialog"
               @opened="onOpened()"
               :show-close="false"
               :close-on-click-modal="false"
               width="100%"
               custom-class="intelligentDialog">
      <el-form :model="formData" label-position="top">

        <el-card>
          <template #header>
            <span>{{ t('deviceManage.dialogPropConfigTitle') }}</span>
          </template>
          <div>
            <el-row>
              <el-col :span="10">
                <el-form-item :label="t('deviceManage.formDeviceName')">
                  <el-input v-model.trim="formData.name" clearable/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('deviceManage.formCable')">
                  <el-select :disabled="this.type==='update'" v-model="formData.cable" value-key="name">
                    <el-option
                        v-for="item in cables.list"
                        :key="item.name"
                        :label="getCableName(item)"
                        :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item :label="t('deviceManage.formDeviceModel')">
                  <el-select :disabled="this.type==='update'" v-model="formData.model" value-key="name"
                             @change="onDeviceModelChange">
                    <el-option
                        v-for="item in $store.getters['models/getIntelligents']"
                        :key="item.name"
                        :label="$store.state.user.lang === 'zh' ? item.name : t('dbDeviceModels.'+item.name)"
                        :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formBox')">
                  <el-select v-model="formData.ground" value-key="name">
                    <el-option
                        v-for="item in formData.cable.gndboxs"
                        :key="item.name"
                        :label="item.name"
                        :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formCompany')">
                  <el-select v-model="formData.devCompany" value-key="name" :disabled="type==='update'"
                             @change="companyChange">
                    <template v-if="user.userInfo.id===1 || type==='update'">
                      <template v-for="item in companies.list" :key="item.name">
                        <el-option :label="item.name" :value="item"/>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="item in companies.list" :key="item.name">
                        <el-option v-if="item.id===user.userInfo.devCompanyId" :label="item.name" :value="item"/>
                      </template>
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formMaintenance')">
                  <el-select v-model="formData.maintenance" value-key="name"
                             @change="companyChange">
                    <template v-for="item in companies.list" :key="item.name">
                      <el-option
                          v-if="isMaintainer(item.type)"
                          :label="item.name"
                          :value="item"
                      />
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formManufacture')">
                  <el-select v-model="formData.manufacture" value-key="name"
                             @change="companyChange">
                    <template v-for="item in companies.list" :key="item.name">
                      <el-option
                          v-if="isManufacturer(item.type)"
                          :label="item.name"
                          :value="item"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1">
              </el-col>
              <el-col :span="7">
                <el-form-item :label="t('deviceManage.formMaintainerId')">
                  <el-select v-model="formData.maintainerId">
                    <el-option v-for="item in maintainerList" :key="item.id" :value="item.id"
                               :label="item.userName + '-' + item.nickName"/>
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formOperationAt')">
                  <el-date-picker v-model="formData.operationat" type="date" clearable/>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formServiceLife')">
                  <el-input v-model.number="formData.serviceLife" clearable/>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formUserId')">
                  <el-input v-model.trim="formData.userId" clearable/>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formDeviceCode')">
                  <el-input v-model.trim="formData.deviceCode" clearable/>
                </el-form-item>
              </el-col>
              <el-col :span="1">
              </el-col>
              <el-col :span="7">
                <el-form-item :label="t('deviceManage.formGpsModel')">
                  <el-select v-model="formData.gpsmodel">
                    <el-option
                        v-for="item in models.gpsModels"
                        :key="item.value"
                        :label="$store.state.user.lang === 'zh' ? item.name : t('dbGpsModels.'+item.name)"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formLongitude')">
                  <el-input v-model.trim="formData.longitude" clearable/>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formLatitude')">
                  <el-input v-model.trim="formData.latitude" clearable/>
                </el-form-item>
                <el-form-item :label="t('deviceManage.formComment')">
                  <el-input v-model.trim="formData.comment" clearable/>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card width="400">
          <template #header>
            <div><span>{{ t('deviceManage.dialogDataConfigTitle') }}</span></div>
          </template>

          <el-table ref="multipleTableRef" :data="dataFmt.units" stripe max-height="400px">
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column :label="t('deviceManage.dataItem')" width="120">
              <template #default="{ row }">
                {{ $store.state.user.lang === 'zh' ? row.name : row.keyword }}
              </template>
            </el-table-column>
            <el-table-column :label="t('deviceManage.dataItemValid')" width="80">
              <template #default="scope">
                <el-switch
                    v-model="scope.row.data.enable"
                    active-color="#13ce66"
                    inactive-color="#C0CCDA">
                </el-switch>
              </template>
            </el-table-column>


            <el-table-column :label="t('deviceManage.alertGrade')" clearable width="150">
              <template #default="scope">
                <el-select v-model.number="scope.row.data.alertGrade">
                  <el-option
                      v-for="item in models.alertGrades"
                      :key="item.value"
                      :label="$store.state.user.lang === 'zh' ? item.name : this.t('dbAlertGrades.' + item.name)"
                      :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column :label="t('deviceManage.lowerThreshold')" width="180">
              <template #default="scope">
                <el-input-number v-model="scope.row.data.thresholdLow"/>
              </template>
            </el-table-column>
            <el-table-column :label="t('deviceManage.upperThreshold')" width="180">
              <template #default="scope">
                <el-input-number v-model="scope.row.data.thresholdErr"/>
              </template>
            </el-table-column>
          </el-table>

        </el-card>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">{{ t('deviceManage.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="enterDialog">{{ t('deviceManage.confirm') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>

  <el-dialog v-model="deviceDataOn">
    <device-data :device-id="deviceId"/>
  </el-dialog>

  <el-drawer v-model="migrateVisible" :size="800">
    <migrate :device="migratingDevice"/>
  </el-drawer>
</template>

<script>
import {mapState, mapGetters} from "vuex"
import {ElMessage, ElMessageBox} from 'element-plus'
import {ref} from 'vue'
import service from '@/utils/request'
import {store} from "@/store";
import lodash from 'lodash'
import {formatDate} from '@/utils/format'
import qs from 'qs'
import {parseTypeCode} from "@/utils/companyCode";
import DeviceData from './DeviceData.vue'
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import {getCableName} from '@/utils/cableNameGen'
import migrate from './migrate.vue'
import {enableDevice, disableDevice, getDevices} from '@/api/devices.js'
import {formatTimeToStr} from "@/utils/date";
import * as XLSX from 'xlsx'
import {useI18n} from "vue-i18n";

export default {
  components: {
    DeviceData,
    DeviceIcon,
    migrate,
  },
  name: 'DevicesIntelligent',
  data() {
    return {
      deviceDataOn: false,
      deviceId: undefined,
      dataFmt: {},
    }
  },
  methods: {
    showDeviceData(id) {
      this.deviceDataOn = true
      this.deviceId = id
    },
    onSelect() {
      let datacfgs = [];
      this.dataFmt.units.forEach(dataFmtItem => {
        if (dataFmtItem.data.enable == true) {
          datacfgs.push(dataFmtItem);
        }
      })
    },

    onOpened() {
      let grounds = this.$store.getters['models/getGrounds']
      if (!this.formData.model && grounds.length > 0)
        this.formData.model = grounds[0];

      if (!this.formData.cable && this.cables.list.length > 0)
        this.formData.cable = this.cables.list[0];

      this.loadMaintainerList()
    },
    // 重置
    onReset() {
      this.useSearchInfo = false
      this.searchInfo = {}
      this.onSubmit()
    },
    // 更新行
    async updateDevDevicesFunc(row) {
      this.formData.cable = this.$store.getters['cables/getById'](row.cableId)
      if (!this.formData.cable.gndboxs) {
        this.formData.cable.gndboxs = [];
      }

      this.type = 'update'
      this.formData = row
      if (this.formData.maintainerId === 0) {
        this.formData.maintainerId = undefined
      }

      this.loadMaintainerList()

      if (!this.formData.ground) {
        let ground = this.$store.getters["cables/getDeviceById"](this.formData.boxId);
        if (ground && ground.id) {
          this.formData.ground = ground;
          if (this.formData.cableId !== ground.cableId) {
            this.formData.cableId = ground.cableId;
          }
        }
      }
      if (!this.formData.cable || !this.formData.cable.id) {
        let cable = this.$store.getters["cables/getById"](this.formData.cableId);
        if (cable && cable.id) {
          this.formData.cable = cable;
        }
      }

      if (!this.formData.cable) {
        this.formData.cable = {};
      }
      if (!this.formData.ground) {
        this.formData.ground = {};
      }

      this.dataFmt = lodash.cloneDeep(this.formData.model.dataFmt)

      this.dataFmt.units.forEach(dataFmtItem => {
        dataFmtItem.data = {
          enable: false,
          thresholdLow: 0,
          thresholdErr: 50,
        };
      })
      this.formData.devCfgs.forEach(devCfg => {
        for (let dataFmtItem of this.dataFmt.units) {
          if (devCfg.devDataUnitId === dataFmtItem.id) {
            dataFmtItem.data = devCfg;
            break;
          }
        }
      })
      this.dialogFormVisible = true
    },

    async sendDataCfgs(device) {
      if (!device || !device.id) {
        return;
      }
      let datacfgsPost = [];
      let datacfgsUpdate = [];
      let datacfgsDelete = [];
      this.dataFmt.units.forEach(dataFmtItem => {
        if (dataFmtItem.data.enable == true) {
          dataFmtItem.data.devDeviceID = device.id;
          dataFmtItem.data.devDataUnitId = dataFmtItem.id;
          delete dataFmtItem.data.dataUnit
          if (dataFmtItem.data.id && dataFmtItem.data.id > 0) {
            datacfgsUpdate.push(dataFmtItem.data);
          } else {
            datacfgsPost.push(dataFmtItem.data);
          }
        } else {
          if (dataFmtItem.data.id && dataFmtItem.data.id > 0) {
            datacfgsDelete.push(dataFmtItem.data);
          }
        }
      })

      if (datacfgsPost.length > 0) {
        service({
          url: '/api/v1/devDataCfgs/' + device.id,
          method: "post",
          data: datacfgsPost
        })
            .catch((e) => {
              console.log(e);
            });
      }
      if (datacfgsUpdate.length > 0) {
        service({
          url: '/api/v1/devDataCfgs/' + device.id,
          method: 'patch',
          data: datacfgsUpdate
        })
            .catch((e) => {
              console.log(e);
            });
      }
      if (datacfgsDelete.length > 0) {
        service({
          url: '/api/v1/devDataCfgs/' + device.id,
          method: 'delete',
          data: datacfgsDelete
        })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    clearPostData(fmt) {
      let postData = lodash.cloneDeep(fmt)
      delete postData.model;
      delete postData.cable;
      delete postData.ground;
      delete postData.maintenance;
      delete postData.manufacture;
      delete postData.createdat;
      delete postData.devCompany;
      delete postData.uuid;
      delete postData.enable;
      delete postData.devCfgs;
      delete postData.manufacture;
      if (this.type !== 'update') {
        delete postData.id;
      }
      return postData
    },
    // 弹窗确定
    async enterDialog() {
      if (
          !this.formData.model?.id || !this.formData.devCompany?.id || !this.formData.ground?.id ||
          !this.formData.name || !this.formData.deviceCode
      ) {
        ElMessage({
          type: 'error',
          message: this.t('deviceManage.messageEmptyFieldError')
        })
        return
      }
      this.formData.cableId = 0;
      this.formData.modelId = this.formData.model.id;
      this.formData.companyId = this.formData.devCompany.id;
      if (this.formData.maintenance && this.formData.maintenance.id) {
        this.formData.maintenanceId = this.formData.maintenance.id;
      }
      if (this.formData.manufacture && this.formData.manufacture.id) {
        this.formData.manufacturerId = this.formData.manufacture.id;
      }

      this.formData.boxId = this.formData.ground.id;
      let postData = this.clearPostData(this.formData)

      service({
        url: "/api/v1/devices",
        method: this.type === 'update' ? 'patch' : "post",
        data: postData,
      }).catch((e) => {
        console.log(e);
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: this.t('deviceManage.createOrEditSuccess')
          })

          if (this.type !== 'update') {
            postData.id = res.data.id
//            this.sendDataCfgs(res.data)
          }
          this.sendDataCfgs(postData)
          this.closeDialog()
          this.getTableData()
          this.$store.dispatch("cables/request", true);
        }
      }).catch((e) => {
        console.log(e);
      })
    },
    onDeviceModelChange(val) {
      this.dataFmt = lodash.cloneDeep(val.dataFmt)
      this.dataFmt.units.forEach(dataFmtItem => {
        dataFmtItem.data = {enable: false};
      })
    },
    getCableName,
  },
  computed: {
    ...mapState([
      'models', 'cables', 'companies', 'user'
    ]),
    ...mapGetters(
        {getModelsArray: 'models/getArray'},
    ),
  },

  setup() {
    const {t} = useI18n()

    const formDataOriginal = {
      name: t('deviceManage.newDeviceNamePlaceholder'),
      boxId: 1,
      cableId: 0,
      cable: {},
      ground: {},
      model: {},
      modelId: 0,
      gpsmodel: 0,
      longitude: '',
      latitude: '',
      gpsDevID: 0,
      length: 0,
      devCompany: {},
      companyId: 0,
      maintenance: {},
      maintenanceId: 0,
      manufacture: {},
      manufacturerId: 0,
      queue: 0,
      serviceLife: 5,
      comment: '',
      devCfgs: {},
      maintainerId: undefined,
    }
    const formData = ref(formDataOriginal)

    function isMaintainer(code) {
      return parseTypeCode(code).includes(1)
    }

    function isManufacturer(code) {
      return parseTypeCode(code).includes(2)
    }

// =========== 表格控制部分 ===========
    const page = ref(1)
    const total = ref(0)
    const pageSize = ref(15)
    const tableData = ref([])
    const searchInfo = ref({})
    const useSearchInfo = ref(false)

// 搜索
    const onSubmit = () => {
      page.value = 1
      useSearchInfo.value = true
      getTableData()
    }

// 分页
    const handleSizeChange = (val) => {
      pageSize.value = val
      getTableData()
    }

// 修改页面容量
    const handleCurrentChange = (val) => {
      page.value = val
      getTableData()
    }

// 查询
    const getTableData = async () => {
      let params = {
        page: page.value,
        pageSize: pageSize.value,
        ext: true,
        type: 3,
      }
      if (useSearchInfo.value) {
        params = {...params, ...searchInfo.value}
      }
      service({
        url: '/api/v1/devices',
        method: "get",
        params,
        paramsSerializer: params => {
          return qs.stringify(params, {indices: false})
        }
      })
          .then((res) => {
            if (res.code === 0) {
              if (res.data.list) {
                tableData.value = res.data.list;
                total.value = res.data.total;
                page.value = res.data.page;
                pageSize.value = res.data.pageSize;
              } else {
                tableData.value = [];
                total.value = 0;
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
    };

    getTableData();

// 多选数据
    const multipleSelection = ref([])
// 多选
    const handleSelectionChange = (val) => {
      multipleSelection.value = val
    }

// 删除行
    const deleteRow = (row) => {
      ElMessageBox.confirm(t('deviceManage.deleteConfirmQuestion'), t('deviceManage.deleteConfirmTitle'), {
        confirmButtonText: t('deviceManage.confirm'),
        cancelButtonText: t('deviceManage.cancel'),
        type: 'warning'
      }).then(() => {
        deleteDevDevicesFunc(row)
      })
    }


// 批量删除控制标记
    const deleteVisible = ref(false)

// 多选删除
    const onDelete = async () => {
      if (multipleSelection.value.length === 0) {
        ElMessage({
          type: 'warning',
          message: t('deviceManage.messageDeleteNoSelection')
        })
        return
      }
      multipleSelection.value &&
      multipleSelection.value.map(item => {
        service({
          url: '/api/v1/devices/' + item.id,
          method: 'delete',
          data: {id: item.id}
        }).then((res) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: t('deviceManage.deleteSuccess')
            })
            if (tableData.value.length === multipleSelection.value.length && page.value > 1) {
              page.value--
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      })

      deleteVisible.value = false
      getTableData()
    }


// 行为控制标记（弹窗内部需要增还是改）
    const type = ref('')

// 删除行
    const deleteDevDevicesFunc = async (row) => {
      service({
        url: '/api/v1/devices/' + row.id,
        method: 'delete',
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: t('deviceManage.deleteSuccess')
          })

          if (tableData.value.length === 1 && page.value > 1) {
            page.value--
          }
          getTableData();
        }
      }).catch((e) => {
        console.log(e);
      });
    }

// 使能某个设备
    const enableDevDevicesFunc = async (id, enable) => {
      service({
        url: '/api/v1/devices/' + id,
        method: 'patch',
        params: {
          enable: enable
        },
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: t('deviceManage.updateSuccess')
          })
        }
      }).catch((e) => {
        console.log(e);
      });
    }

// 弹窗控制标记
    const dialogFormVisible = ref(false)

// 打开弹窗
    const openDialog = () => {
      type.value = 'create'
      formData.value.name = "device-" + (tableData.value.length + 1)
      dialogFormVisible.value = true
    }

// 关闭弹窗
    const closeDialog = () => {
      formData.value = formDataOriginal
      dialogFormVisible.value = false
    }

    const maintainerList = ref([])

    async function loadMaintainerList() {
      let waits = []
      let l = [],
          companyIds = []
      for (let c of ['devCompany', 'maintenance', 'manufacture']) {
        let company = formData.value[c]
        if (!company || !company.id) {
          continue
        }
        if (companyIds.indexOf(company.id) > -1) {
          continue
        }
        companyIds.push(company.id)

        let companyInStore = store.state.companies.idMap.get(company.id)
        if (!companyInStore) {
          continue
        }
        if (companyInStore.users) {
          l = [...l, ...companyInStore.users]
        } else {
          let wait = store.dispatch('companies/requestExt', company.id)
              .then(() => {
                let c = store.state.companies.idMap.get(company.id)
                if (c.users) {
                  l = [...l, ...store.state.companies.idMap.get(company.id).users]
                }
              })
          waits.push(wait)
        }
      }
      if (waits.length > 0) {
        await Promise.all(waits)
      }
      maintainerList.value = l
    }

    async function companyChange() {
      await loadMaintainerList()
    }

    // 迁移数据
    const migrateVisible = ref(false)
    const migratingDevice = ref({})

    // 导出

    const exportTableHeadings = [
      '设备编号',
      '设备类型',
      '位置描述',
      '所属公司',
      '运维厂商',
      '生产厂家',
      '所属线路',
      '杆塔沟/接地箱',
      '接地箱设备编号',
      '接地类型',
      '箱体型式',
      '接地箱所属公司',
      '省',
      '市',
      '电压等级',
      '安装位置区段',
      '线路长度',
      '在线状态',
      '最新数据时间',
    ]

    const boxBodyModels = ['立式', '壁挂', '固封']

    function getTableRow(deviceId) {
      let device = store.getters['cables/getDeviceById'](deviceId),
        box = store.getters['cables/getDeviceById'](device.boxId)
      if (!box || !box.enable || !device || !device.enable) {
        return []
      }
      let userCompany = store.getters['companies/getById'](device.companyId),
          keeperCompany = store.getters['companies/getById'](device.maintenanceId),
          makerCompany = store.getters['companies/getById'](device.manufacturerId),
          cable = box ? store.getters['cables/getById'](box.cableId) : undefined,
          boxCompany = box ? store.getters['companies/getById'](box.companyId) : undefined,
          deviceValue = store.getters['cables/getDeviceValueById'](deviceId)
      if (box.name === device.boxId) {
        // 我不知道为啥查不到的时候会给我返回这个, 不是我写的
        box = undefined
      }
      return [
        device.deviceCode,
        device.model.name,
        device.name,
        userCompany.name,
        keeperCompany.name,
        makerCompany.name,
        cable.name,
        // getCableName(cable),
        box ? box.name : '',
        box ? box.deviceCode : '',
        box ? box.model.name : '',
        box ? (box.boxBodyModel > 0 ? boxBodyModels[box.boxBodyModel - 1] : '') : '',
        box ? boxCompany.name : '',
        (box && cable) ? cable.provincial : '',
        (box && cable) ? cable.city : '',
        (box && cable) ? cable.voltage_level : '',
        (box && cable) ? cable.section : '',
        (box && cable) ? cable.length : '',
        deviceValue?.online ? '在线' : '离线',
        ((typeof deviceValue?.time === 'string') && deviceValue?.time.startsWith('0000-00-00')) ? '' : formatTimeToStr(deviceValue.time * 1000),
        // 排序用
        device.companyId,
        box ? box.cableId : -1,
        box ? box.queue : -1,
        device.modelId
      ]
    }

    function exportDevices() {
      const filename = '设备台账.xlsx',
          sheet_name = 'Sheet1'
      let aoa = [exportTableHeadings],
          colsCount = exportTableHeadings.length
      let deviceIds = []

      if (multipleSelection.value.length > 0) {
        // 如果多选
        deviceIds = multipleSelection.value.map(d => d.id)
      } else {
        for (let [id, device] of store.state.cables.deviceIdMap) {
          if ([6, 7, 8].includes(device.modelId)) {
            continue
          }
          // 如果查询
          let box = store.getters['cables/getDeviceById'](device.boxId)
          if (useSearchInfo.value) {
            if ([6, 7, 8].includes(device.modelId)) {
              continue
            }
            if (searchInfo.value.userCompany && device.companyId !== searchInfo.value.userCompany) {
              continue
            }
            if (searchInfo.value.cable && box.cableId !== searchInfo.value.cable) {
              continue
            }
            if (searchInfo.value.userId && !device.userId.includes(searchInfo.value.userId)) {
              continue
            }
            if (searchInfo.value.deviceCode && !device.deviceCode.includes(searchInfo.value.deviceCode)) {
              continue
            }
          }
          deviceIds.push(id)
        }
      }

      for (let id of deviceIds) {
        let row = getTableRow(id)
        if (!row || row.length < 1) {
          continue
        }
        aoa.push(row)
      }

      // 排序
      aoa.sort((a, b) => {
        return a[colsCount] - b[colsCount]  // 公司id
            || a[colsCount + 1] - b[colsCount + 1]  // 线路id
            || a[colsCount + 2] - b[colsCount + 2]  // 接地箱顺序
            || a[colsCount + 3] - b[colsCount + 3]  // 设备类型
      })

      // 删掉排序用的字段
      aoa = aoa.map((el) => {
        return el.slice(0, colsCount)
      })

      // 写文件
      let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(aoa)
      XLSX.utils.book_append_sheet(wb, ws, sheet_name)
      XLSX.writeFile(wb, filename)
    }

    // 批量使能
    function handleBatchEnable() {
      let tasks = multipleSelection.value.reduce((prev, item) => {
        return [...prev, enableDevice(item.id)]
      }, [])
      Promise.all(tasks).then(() => {
        getTableData()
      })
    }

    function handleBatchDisable() {
      let tasks = multipleSelection.value.reduce((prev, item) => {
        return [...prev, disableDevice(item.id)]
      }, [])
      Promise.all(tasks).then(() => {
        getTableData()
      })
    }

    return {
      t,
      formData,
      page,
      total,
      pageSize,
      tableData,
      searchInfo,
      useSearchInfo,
      onSubmit,
      handleSizeChange,
      handleCurrentChange,
      getTableData,
      multipleSelection,
      handleSelectionChange,
      deleteRow,
      deleteVisible,
      onDelete,
      type,
      deleteDevDevicesFunc,
      enableDevDevicesFunc,
      dialogFormVisible,
      openDialog,
      closeDialog,
      formatDate,
      isMaintainer,
      isManufacturer,
      maintainerList,
      companyChange,
      loadMaintainerList,
      migrateVisible,
      migratingDevice,
      exportDevices,
      handleBatchEnable,
      handleBatchDisable,
    }
  },
}
</script>

<style scoped>
.box {
  height: 820px;
}

.search-box {
  height: 50px;
}

.pager {
  z-index: 99;
}
</style>

<style>
.intelligentDialog {
  width: 800px;
}
</style>
