<template>
  <el-drawer v-model="dialogVisible" :size="drawerSize" :before-close="handleUploadDialogClose">
    <div>
      <el-steps :active="activeStep" finish-status="success">
        <el-step title="上传"/>
        <el-step title="检查"/>
        <el-step title="导入"/>
      </el-steps>

      <!--    第一步：上传-->
      <template v-if="activeStep === 0">
        <el-upload
            drag
            multiple
            :limit="1"
            :auto-upload="false"
            accept="xlsx"
            :on-exceed="handleExceed"
            v-model:file-list="uploadFileList"
            ref="uploader"
        >
          <el-icon class="el-icon--upload">
            <upload-filled/>
          </el-icon>
          <div class="el-upload__text"><em>点击上传</em></div>
        </el-upload>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <el-link href="/files/台账模板.xlsx" target="_blank">下载模板</el-link>
          <el-button type="primary" @click="importFromXlsx" :disabled="uploadFileList.length < 1"
          >上传
          </el-button
          >
        </div>
      </template>

      <!--    第二步：检查-->
      <template v-if="activeStep === 1">
        <p>
          上传完成。 有效数据 {{ validRowsCount }} 行。
          <template v-if="invalidRowList.length > 0">
            第
            {{
              invalidRowList.length > 3
                  ? invalidRowList.slice(0, 4).join('、')
                  : invalidRowList.join('、')
            }}
            行等 {{ invalidRowList.length }} 行无效。
          </template>
          <template v-if="validRowsCount === 0">请检查您的文件后重新上传。</template>
        </p>
        <div
            style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
          "
        >
          <el-button @click="() => activeStep -= 1" :icon="Back">上一步</el-button>
          <el-button type="primary" :icon="Right" :disabled="validRowsCount === 0" @click="goImport"
          >继续导入
          </el-button
          >
        </div>
      </template>

      <!--    第三步：导入-->
      <template v-if="activeStep > 1">
        <el-divider/>
        <h2>批量创建任务</h2>
        <el-progress
            :text-inside="true"
            :stroke-width="24"
            :percentage="Math.floor((counts.boxCreate.success + counts.deviceCreate.success)) /
                (counts.boxCreate.total + counts.deviceCreate.total) * 100"
            status="success"
        />
        <p>总任务：{{ counts.boxCreate.total + counts.deviceCreate.total }}</p>
        <p>成功：{{ counts.boxCreate.success + counts.deviceCreate.success }}</p>
        <p>失败：{{ counts.boxCreate.failure + counts.deviceCreate.failure }}</p>
        <template v-if="needUpdate">
          <el-divider/>

          <h2>批量更新任务</h2>
          <el-progress
              :text-inside="true"
              :stroke-width="24"
              :percentage="Math.floor((counts.boxUpdate.success + counts.deviceUpdate.success) /
                (counts.boxUpdate.total + counts.deviceUpdate.total)) * 100"
              status="success"
          />
          <p>总任务：{{ counts.boxUpdate.total + counts.deviceUpdate.total }}</p>
          <p>成功：{{ counts.boxUpdate.success + counts.deviceUpdate.success }}</p>
          <p>失败：{{ counts.boxUpdate.failure + counts.deviceUpdate.failure }}</p>
        </template>
        <div style="display: flex; flex-direction: row; justify-content: flex-end">
          <el-button type="success" :disabled="activeStep < 3" @click="exit()">完成</el-button>
        </div>
      </template>

      <!--    预览-->
      <template v-if="activeStep > 0">
        <el-button @click="togglePreview">预览</el-button>
        <el-table v-if="previewVisible" :data="box_list" max-height="650">
          <el-table-column label="行号" prop="row" width="60"/>
          <!--          <el-table-column label="设备名称" prop="productName"/>-->
          <el-table-column label="设备标识" prop="deviceCode"/>
          <el-table-column label="唯一识别码" prop="userId"/>
          <!--          <el-table-column label="设备类型" prop="product_model"/>-->
          <el-table-column label="设备类型" prop="deviceType"/>
          <el-table-column label="安装位置" prop="deviceName"/>
          <el-table-column label="杆塔沟/接地箱" prop="name"/>
          <el-table-column label="接地类型" prop="modelStr"/>
          <el-table-column label="箱体型式" prop="boxBodyModelStr"/>
          <el-table-column label="连接顺序" prop="queue"/>
          <el-table-column label="电缆长度" prop="length"/>
          <!--          <el-table-column label="GPS模式" prop="gpsmodelStr"/>-->
          <el-table-column label="经度" prop="longitude"/>
          <el-table-column label="纬度" prop="latitude"/>
          <el-table-column label="线路名称" prop="cableName"/>
          <el-table-column label="线路代号" prop="cableAbbr"/>
          <el-table-column label="公司" prop="companyName"/>
          <el-table-column label="公司代号" prop="companyAbbr"/>
          <el-table-column label="运维厂商" prop="maintenanceName"/>
          <el-table-column label="运维厂商代号" prop="maintenanceAbbr"/>
          <el-table-column label="生产厂家" prop="manufacture"/>
          <el-table-column label="生产厂家代号" prop="manufactureAbbr"/>
          <el-table-column label="共用电缆接头的设备的设备编号" prop="conjunct"/>
          <el-table-column label="导入状态">
            <template #default="{ row }">
              <div
                  :set="(stat = getRowStatus(row.status))"
                  style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-result style="transform: scale(0.3); padding: 0" :icon="stat[0]"/>
                <span
                >{{ stat[1] }}
                  <template v-if="stat[1].includes('数据已存在') && activeStep > 1"
                  >，可以<a style="color: #5cd9e8; cursor: pointer" @click="updateSingleRow(row)">更新</a></template
                  ></span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </el-drawer>
</template>

<script setup>
import {defineEmits, defineProps, ref, computed, reactive} from 'vue'
import * as XLSX from 'xlsx'
import {ElMessageBox, genFileId} from 'element-plus'
import {addDevice, updateDevice} from '@/api/devices'

import {useStore} from 'vuex'


import {Back, Right} from '@element-plus/icons-vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
})

const store = useStore()

const emit = defineEmits(['update:modelValue'])

const dialogVisible = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

const drawerSize = ref('50%')

function togglePreview() {
  previewVisible.value = !previewVisible.value
  if (previewVisible.value) {
    drawerSize.value = '95%'
  } else {
    drawerSize.value = '50%'
  }
}

// upload 组件的属性
const uploader = ref() // upload 组件的 ref
const uploadFileList = ref([])

// 当前上传进度
const activeStep = ref(0)

const box_list = ref([])  // 产品代号校验通过的有效行
const device_list = ref([])

const validRowsCount = ref(0) // 创建成功的行数
const invalidRowList = ref([])

const previewVisible = ref(false)

const createSuccesses = ref(0)
const needUpdate = ref(false)
const updateSuccesses = ref(0)
const updateFailures = ref(0)

let deviceCode2idMap = {}
let boxCode2idMap = {}

// 线路id: 最大的queue
let queues = {}

// 用来展示进度条
let default_results = {
  total: 0,
  success: 0,
  failure: 0,
}

const counts = reactive({
  boxCreate: {...default_results},
  deviceCreate: {...default_results},
  boxUpdate: {...default_results},
  deviceUpdate: {...default_results},
})

// 内容: box_list 里的 index
let boxCreates = []
let boxUpdates = []
let deviceCreates = {}
let deviceUpdates = {}
function init() {
  drawerSize.value = '50%'

  activeStep.value = 0
  device_list.value = []
  box_list.value = []
  validRowsCount.value = 0
  invalidRowList.value = []

  previewVisible.value = false

  createSuccesses.value = 0
  needUpdate.value = false

  updateSuccesses.value = 0
  updateFailures.value = 0

  deviceCode2idMap = {}
  boxCode2idMap = {}

  queues = {}

  for (let prop in counts) {
    if (prop.success) {
      prop.success = 0
    }
    if (prop.failure) {
      prop.failure = 0
    }
    if (prop.total) {
      prop.total = 0
    }
  }

  // 清空任务列表
  boxCreates = []
  boxUpdates = []
  deviceCreates = {}
  deviceUpdates = {}
}

init()

function importFromXlsx() {
  let reader = new FileReader()
  reader.onload = function (e) {
    let data = XLSX.read(e.target.result, {type: 'binary'})
    processXlsx(data).then(() => {
      activeStep.value = 1
    })
  }
  reader.readAsBinaryString(uploadFileList.value[0].raw)
}

const validDeviceProps = [
  'name',
  'deviceCode',
  'companyId',
  'longitude',
  'latitude',
  'userId',
  'operationat',
]

const validBoxProps = [
  'name',
  'deviceCode',
  'modelId',
  'boxBodyModel',
  'cableId',
  'companyId',
  'queue',
  'length',
  'longitude',
  'latitude',
  'userId',
  'operationat',
  'conjunct',
]

function sumLength(list) {
  return list.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.length
  }, 0)
}


async function processXlsx(data) {
  // 第一步: 简单处理表格, 忽略填写不全的, 标记有问题的 (没有接地箱类型), 标记已存在的
  // 只读取第一张 sheet
  const sheetName = '设备页'
  let sheet = data.Sheets?.[sheetName]
  if (!sheet) {
    return
  }
  // 长宽
  let [columns, rows] = getSheetDimensions(sheet)
  // 只处理 1000 行
  if (rows > 1000) {
    rows = 1000
  }
  // 判断哪一列是什么数据
  let columnPosMap = {}
  for (let i = 0; i < columns; i++) {
    let letter = String.fromCharCode(65 + i),
        cell = sheet[letter + '1']
    if (!cell) {
      continue
    }
    columnPosMap[cell.v] = letter
  }
  // 分别操作每一行
  let devices = []
  let boxes = []
  for (let i = 2; i <= rows; i++) {
    let box = {row: i}
    let device = {row: i}
    for (let [k, v] of Object.entries(columnPosMap)) {
      let cell = sheet[v + i]
      if (!cell) {
        continue
      }
      processCell(k, sheet[v + i], box, device)
    }
    if (box.__head && box.__head.includes('填写要求')) {
      continue
    }
    if (!device.deviceCode || !box.name) {
      continue
    }
    devices.push(device)
    boxes.push(box)
  }
  // createIndexList = []
  let invalids = [],
      valids = 0
  for (let [i, d] of boxes.entries()) {
    // 没有接地类型的行在预览中标记为无效数据
    let invalid_type = '',
        invalid = false
    if (!d.deviceCode) {
      invalid = true
      invalid_type = 'invalid_device_code'
    } else if (!d.modelId) {
      invalid = true
      invalid_type = 'invalid_model_id'
    } else if (d.cableAbbr && !d.cableId) {
      invalid = true
      invalid_type = 'invalid_cable_id'
    } else if (d.companyAbbr && !d.companyId) {
      invalid = true
      invalid_type = 'invalid_company_id'
    } else if (d.maintenanceAbbr && !d.maintenanceId) {
      invalid = true
      invalid_type = 'invalid_maintenance_id'
    } else if (d.manufactureAbbr && !d.manufacturerId) {
      invalid = true
      invalid_type = 'invalid_manufacturer_id'
    }
    if (invalid) {
      d.status = invalid_type // 标记 这一行有问题
      invalids.push(d.row)
      continue
    }

    d.status = 'to_create' // 标记 这一行待创建
    valids += 1

    let olds = store.getters['cables/getDevicesByConds']({
      deviceCode: d.deviceCode,
    })

    let hasSubDevs = {}
    for (let id of d.deviceModelIds) {
      hasSubDevs[id] = false
    }

    for (let old of olds) {
      if ([6, 7, 8].includes(old.model.id)) {
        boxCode2idMap[d.deviceCode] = old.id
        d.status = 'has_box'
      } else if (d.deviceModelIds.includes(old.model.id)) {
        if (!deviceCode2idMap[old.model.id]) {
          deviceCode2idMap[old.model.id] = {}
        }
        deviceCode2idMap[old.model.id][d.deviceCode] = old.id
        hasSubDevs[old.model.id] = true
      }
    }

    if (d.status === 'to_create') {
      boxCreates.push(i)
    } else if (d.status === 'has_box') {
      boxUpdates.push(i)
    }

    for (let [key, value] of Object.entries(hasSubDevs)) {
      if (value) {
        if (!deviceUpdates[key]) {
          deviceUpdates[key] = []
        }
        d.status += '_' + store.getters["models/getById"](parseInt(key)).dataFmt.abbreviation
        deviceUpdates[key].push(i)
      } else {
        if (!deviceCreates[key]) {
          deviceCreates[key] = []
        }
        deviceCreates[key].push(i)
      }
    }
  }

  counts.boxCreate.total = boxCreates.length
  counts.deviceCreate.total = sumLength(Object.values(deviceCreates))
  counts.boxUpdate.total = boxUpdates.length
  counts.deviceUpdate.total = sumLength(Object.values(deviceUpdates))
  invalidRowList.value = invalids
  validRowsCount.value = valids
  device_list.value = devices
  box_list.value = boxes
}

function getSheetDimensions(sheet) {
  let furthestCell = sheet['!ref'].split(':')[1]
  let rowsMatchArray = furthestCell.match(/[0-9]{1,}/),
      colsMatchArray = furthestCell.match(/[A-Z]{1,}/)
  let colsChar = colsMatchArray[0]
  let x = colsChar.charCodeAt(0) - 65 + 1
  // 如果超过了 Z 列
  if (colsChar.length > 1) {
    let firstLetterPos = x,
        secondLetterPos = colsChar.charCodeAt(1) - 65 + 1
    x = firstLetterPos * 26 + secondLetterPos
  }
  // 超过 ZZ 就不管了
  if (colsChar.length > 2) {
    x = 99
  }
  return [x, parseInt(rowsMatchArray[0])]
}

let modelIds = {
  "直接": 6,
  "交叉": 7,
  "保护": 8,
}

function getModelId(modelStr) {
  return _getIdByStr(modelStr, modelIds)
}

let bodyModelIds = {
  "立式": 1,
  "壁挂": 2,
  "固封": 3,
}

function getBodyModelId(modelStr) {
  return _getIdByStr(modelStr, bodyModelIds)
}

let gpsModelIds = {
  '无效': 0,
  '手动设置': 1,
  '自动获取': 2,
  '从其他设备获取': 3,
}

function _getIdByStr(str, map) {
  let id = map[str]
  if (!id) {
    for (let [k, v] of Object.entries(map)) {
      if (str.includes(k)) {
        id = v
        break
      }
    }
  }
  return id
}

function getGpsModelId(modelStr) {
  return _getIdByStr(modelStr, gpsModelIds)
}

function parseNumber(str) {
  let num = parseInt(str)
  if (isNaN(num)) {
    num = 0
  }
  return num
}

function getTypes(typeStr) {
  return typeStr.split(" ").filter(x => x.length > 0)
}

function getTypeIds(typeStr) {
  let r = []
  for (let t of getTypes(typeStr)) {
    let model = store.getters["models/getByKeyword"](t)
    if (model) {
      r.push(model.id)
    }
  }
  return r
}

function processCell(columnName, cell, box, device) {
  // device 用于创建智能设备
  // box 用于创建接地箱以及展示
  columnName = columnName.trim()
  let cellValue = String(cell.v).trim()
  switch (columnName) {
    case '序号':  // 填写说明
      box['__head'] = cellValue
      break
    case '安装位置':
      device['name'] = cellValue
      box['deviceName'] = cellValue
      break
    case '管理平台设备类型':
      box['deviceType'] = getTypes(cellValue)
      box['deviceModelIds'] = getTypeIds(cellValue)
      break
    case '杆塔沟/接地箱':
      box['name'] = cellValue
      break
    case '设备标识':
      box['deviceCode'] = cellValue
      device['deviceCode'] = cellValue
      break
    case '接地类型':
      box['modelStr'] = cellValue
      box['modelId'] = getModelId(cellValue)
      break
    case '箱体型式':
      box['boxBodyModelStr'] = cellValue
      box['boxBodyModel'] = getBodyModelId(cellValue)
      break
    case '线路名称': {
      box['cableName'] = cellValue
      break
    }
    case '线路代号': {
      box['cableAbbr'] = cellValue
      let cable = store.getters['cables/getByAbbr'](cellValue)
      if (cable) {
        box['cableId'] = cable.id
      }
      break
    }
    case '公司': {
      box['companyName'] = cellValue
      break
    }
    case '公司代号': {
      box['companyAbbr'] = cellValue
      let company = store.getters['companies/getByAbbr'](cellValue)
      if (company) {
        box['companyId'] = company.id
        device['companyId'] = company.id
      }
      break
    }
    case '运维厂商': {
      box['maintenanceName'] = cellValue
      break
    }
    case '运维厂商代号': {
      box['maintenanceAbbr'] = cellValue
      let company = store.getters['companies/getByAbbr'](cellValue)
      if (company) {
        box['maintenanceId'] = company.id
        device['maintenanceId'] = company.id
      }
      break
    }
    case '生产厂家': {
      box['manufactureName'] = cellValue
      break
    }
    case '生产厂家代号': {
      box['manufactureAbbr'] = cellValue
      let company = store.getters['companies/getByAbbr'](cellValue)
      if (company) {
        box['manufacturerId'] = company.id
        device['manufacturerId'] = company.id
      }
      break
    }
    case '连接顺序': {
      box['queue'] = parseNumber(cellValue)
      break
    }
    case '电缆长度': {
      box['length'] = parseNumber(cellValue)
      break
    }
    case '经度': {
      box['longitude'] = cellValue
      device['longitude'] = cellValue
      break
    }
    case '纬度': {
      box['latitude'] = cellValue
      device['latitude'] = cellValue
      break
    }
    case '唯一识别码':
      box['userId'] = cellValue
      device['userId'] = cellValue
      break
    case '投运时间':
      box['operationat'] = new Date(cellValue)
      device['operationat'] = new Date(cellValue)
      break
    case '共用电缆接头的设备的设备编号':
      box['conjunct'] = cellValue
      break
  }
}

function handleExceed(files) {
  uploader.value.clearFiles()
  let file = files[0]
  file.uid = genFileId()
  uploader.value.handleStart(file)
}

function handleUploadDialogClose() {
  if (
      activeStep.value > 2 || // 写入完成
      (activeStep.value === 0 && uploadFileList.value.length < 1) // 已上传文件
  ) {
    exit()
    return
  }
  // 正在写入, 不可关闭
  if (activeStep.value === 2) {
    return
  }
  // 其他情况
  ElMessageBox.confirm('确定要关闭对话框吗？您可能需要重新上传文件。')
      .then(() => {
        exit()
      })
      .catch(() => {
        return
      })
}

function exit() {
  init()
  dialogVisible.value = false
}

function getBoxId(row) {
  return boxCode2idMap[row.deviceCode]
}

function prepareDeviceData(row, id) {
  // 缺失 modelId
  // 后面需要补充 modelId = 9, modelId = 10
  let data = {}
  for (let p of validDeviceProps) {
    data[p] = row[p]
  }
  data.gpsmodel = 2  // 自动获取
  data.boxId = getBoxId(row)
  // 默认德钢
  if (!data.companyId) {
    data.companyId = 1
  }
  data.id = id
  return data
}

function getQueue(row) {
  if (row.queue) {
    return row.queue
  }
  if (row.id) {
    return store.getters['cables/getDeviceById'](row.id)?.queue
  }
  if (queues[row.cableId]) {
    queues[row.cableId] += 10
    return queues[row.cableId]
  }
  let cable = store.getters['cables/getById'](row.cableId)
  let q = -1
  if (!cable) {
    return q
  }
  let boxes = store.getters['cables/getDevicesByConds']({
    cableId: cable.id,
    modelId: [6, 7, 8]
  })
  for (let b of boxes) {
    if (b.queue > q) {
      q = b.queue
    }
  }
  if (q > 0) {
    q += 10
  } else {
    q = 10
  }
  queues[cable.id] = q
  return q
}

function prepareBoxData(row, id) {
  let data = {}
  for (let p of validBoxProps) {
    data[p] = row[p]
  }
  data.gpsmodel = 3  // 从其他设备获取
  if (!data.longitude || !data.latitude) {
    // 宜兴市坐标
    data.longitude = "119.823407"
    data.latitude = "31.340637"
  }
  row.id = id
  data.queue = getQueue(row)

  // 默认德钢
  if (!data.companyId) {
    data.companyId = 1
  }
  data.id = id
  return data
}


async function _taskRunner(deviceType, createOrUpdate) {
  let task_list = boxCreates,
      _prepareFunc = prepareBoxData,
      data_list = box_list,
      idMap = boxCode2idMap,
      apiFunc = addDevice

  if (deviceType === 'box') {
    if (createOrUpdate === 'update') {
      task_list = boxUpdates
    }
  } else {
    _prepareFunc = prepareDeviceData
    data_list = device_list
    task_list = deviceCreates[deviceType]
    idMap = deviceCode2idMap[deviceType]
    if (createOrUpdate === 'update') {
      task_list = deviceUpdates[deviceType]
    }
  }

  let prepareFunc = _prepareFunc
  if (createOrUpdate === 'update') {
    apiFunc = updateDevice
    prepareFunc = (row) => {
      let data = _prepareFunc(row, idMap[row.deviceCode])
      return data
    }
  } else {
    prepareFunc = (row) => {
      let data = _prepareFunc(row)
      if (deviceType !== 'box') {
        data.modelId = parseInt(deviceType)
      }
      return data
    }
  }

  let count_prop_str = (deviceType === 'box' ? 'box' : 'device') + createOrUpdate.slice(0, 1).toUpperCase() + createOrUpdate.slice(1)

  let tasks = []
  if (!task_list) {
    return
  }
  for (let i of task_list) {
    let data = prepareFunc(data_list.value[i])
    tasks.push(apiFunc(data))
  }
  let taskResults = await Promise.all(tasks)
  for (let [i, r] of taskResults.entries()) {
    let box_list_index = task_list[i]
    if (r.code === 0) {
      box_list.value[box_list_index].status = createOrUpdate + 'd'
      counts[count_prop_str].success += 1
      // 如果是 box, 更新 boxCode2idMap
      if (deviceType === 'box') {
        boxCode2idMap[r.data.deviceCode] = r.data.id
      }
    } else {
      box_list.value[box_list_index].status = createOrUpdate + '_error'
      counts[count_prop_str].failure += 1
    }
  }
}

async function goImport() {
  activeStep.value = 2

  await _taskRunner('box', 'create')
  await store.dispatch('cables/request', true)
  for (let model of store.state.models.list) {
    if (model.id > 8) {
      await _taskRunner(String(model.id), 'create')
    }
  }

  let rowsNeedUpdate = counts.boxUpdate.total
  if (rowsNeedUpdate < 1) {
    activeStep.value = 3
    return
  }
  try {
    await ElMessageBox({
      title: '警告',
      message: `${rowsNeedUpdate} 行数据已存在，要全部更新吗？`,
      showCancelButton: true,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      distinguishCancelAndClose: true,
      showClose: false,
    })
    activeStep.value = 3
    needUpdate.value = true
    await _taskRunner('box', 'update')
    for (let model of store.state.models.list) {
      if (model.id > 8) {
        await _taskRunner(model.id, 'update')
      }
    }
  } catch (err) {
    activeStep.value = 3
  }
  store.dispatch('cables/request', true)
}

function getRowStatus(status) {
  if (/has_box.*/.test(status)) {
    return ['warning', `数据已存在（接地箱 ${status.replace('has_box_', '').split('_')}）`]
  }
  switch (status) {
    case 'to_create':
      return ['info', '待创建']
    case 'created':
      return ['success', '创建成功']
    case 'updated':
      return ['success', '更新成功']
    case 'invalid_device_code':
      return ['error', '设备标识无效']
    case 'invalid_model_id':
      return ['error', '接地类型无效']
    case 'invalid_cable_id':
      return ['error', '线路无效']
    case 'invalid_company_id':
      return ['error', '公司无效']
    case 'invalid_maintenance_id':
      return ['error', '运维厂商无效']
    case 'invalid_manufacturer_id':
      return ['error', '生产厂家无效']
    case 'create_error':
      return ['error', '创建错误']
    case 'update_error':
      return ['error', '更新错误']
    default:
      return ['info', '等待检查']
  }
}

async function updateSingleRow(row) {
  let devices = store.getters['cables/getDevicesByConds']({
    deviceCode: row.deviceCode
  })
  let box
  let hasSubDevs = {}
  for (let id of row.deviceModelIds) {
    hasSubDevs[id] = false
  }
  for (let d of devices) {
    if ([6, 7, 8].includes(d.modelId)) {
      box = d
    } else if (Object.keys(hasSubDevs).includes(String(d.model.id))) {
      hasSubDevs[d.model.id] = true
    }
  }

  let tasks = []
  if (!box) {
    tasks.push(addDevice(prepareBoxData(row)))
  } else {
    tasks.push(updateDevice({...prepareBoxData(row, boxCode2idMap[row.deviceCode])}))
  }

  for (let [key, value] of Object.entries(hasSubDevs)) {
    if (value) {
      tasks.push(updateDevice({...prepareDeviceData(row, deviceCode2idMap[parseInt(key)][row.deviceCode])}))
    } else {
      tasks.push(addDevice({...prepareDeviceData(row), modelId: parseInt(key)}))
    }
  }

  let response = await Promise.all(tasks)
  let updateFail = false
  for (let r of response) {
    if (r.code !== 0) {
      updateFail = true
      break
    }
  }
  if (updateFail) {
    row.status = 'update_error'
  } else {
    row.status = 'updated'
  }
}
</script>
