<template>
  <el-button icon="upload" type="primary" style="position: absolute; right: 30px; top: 136px; z-index: 100;" @click="importDialogVisible=true">
    {{ this.t('deviceManage.buttonImport') }}</el-button>
  <div class="manage-center-container">
    <el-tabs type="border-card" v-model="tabsValue" height="800" class="tabs">
<!--      <el-tab-pane label="附件" name="1">-->
<!--          <DevicesAddone v-if="tabsValue == '1'" />-->
<!--      </el-tab-pane>-->
      <el-tab-pane :label="this.t('deviceManage.tabBox')" name="2">
          <DevicesGround v-if="tabsValue == '2'" />
      </el-tab-pane>
      <el-tab-pane :label="this.t('deviceManage.tabDevice')" name="3">
          <DevicesIntelligent v-if="tabsValue == '3'" />
      </el-tab-pane>
    </el-tabs>
  </div>
  <import-dialog v-model="importDialogVisible" />

</template>
<script>
import { ref , shallowRef,} from 'vue'
//import DevicesAll from "./DevicesAll.vue"
import DevicesGround from "./Ground.vue"
import DevicesAddone from "./Addone.vue"
import DevicesIntelligent from "./Intelligent.vue"
import ImportDialog from "./ImportDialog.vue"
import { useI18n } from "vue-i18n";

export default {
  name:'DevicesIndex',
  components: {
    // DevicesAll,
    // DevicesAddone,
    DevicesGround,
    DevicesIntelligent,
    ImportDialog,
  },
  setup () {
    const { device } = shallowRef(0);
    const importDialogVisible = ref(false)
    const { t } = useI18n()
    return {
      t,
      device,
      importDialogVisible,
    }
  },
  data () {
    const tabsValue = ref('2')
    return {
      tabsValue,
    };
  },
  computed: {
    divStyleObject () { 
      return {
        width:"100px",
        height:"100px",
        borderWidth:"1px",
        borderStyle:"solid",
        borderColor:this.device.color
      }
     }, // 3
  //  repositoriesMatchingSearchQuery () {  }, // 2
  },
  watch: {
    device()
    {
      // console.log("DeviceInfo.vue watch device");
    },
    color()
    {
      // console.log("DeviceInfo.vue watch deviceColor");
      // console.log(this.color)
      // console.log(this.device)
      // console.log(this.divStyleObject.borderColor)
      // this.divStyleObject.borderColor = this.color;
      // console.log(this.divStyleObject.borderColor)
    },
  },
  /*
  methods: {
    updateFilters () {  }, // 3
  },
  mounted () {
  }*/
}
</script>
<style scoped>
.tabs {
  background: none;
}
</style>

<style>
.el-tabs__nav {
  background: none !important;
}
</style>
