<template>
  <div>
    <div class="gva-search-box search-box">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start" @keyup.enter="onSubmit">
        <el-form-item :label="t('deviceManage.searchCable')" label-width="50px">
          <el-select v-model="searchInfo.cable" filterable>
            <el-option v-for="c in $store.state.cables.list" :key="c.id" :label="getCableName(c)" :value="c.id"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="$store.state.user.userInfo.id===1" :label="t('deviceManage.searchCompany')" label-width="90px">
          <el-select v-model="searchInfo.userCompany" filterable>
            <el-option v-for="c in $store.state.companies.list" :key="c.id" :label="c.name" :value="c.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="t('deviceManage.searchDeviceCode')" label-width="110px">
          <el-input v-model.trim="searchInfo.deviceCode"/>
        </el-form-item>
        <el-form-item label-width="50px">
          <el-button size="small" type="primary" icon="search" @click="onSubmit">{{
              t('deviceManage.searchSubmit')
            }}
          </el-button>
          <el-button size="small" icon="refresh" @click="onReset">{{ t('deviceManage.searchReset') }}</el-button>
        </el-form-item>
      </div>
    </div>
    <div class="gva-table-box box">
      <div class="gva-btn-list" style="width: 100%">
        <el-button size="small" type="primary" icon="plus" @click="openDialog">{{
            t('deviceManage.formButtonNew')
          }}
        </el-button>
        <el-popover v-model:visible="deleteVisible" placement="top" width="160">
          <p>{{ t('deviceManage.deleteConfirmQuestion') }}</p>
          <div style="text-align: right; margin-top: 8px;">
            <el-button size="small" text @click="deleteVisible = false">{{ t('deviceManage.cancel') }}</el-button>
            <el-button size="small" type="primary" @click="onDelete">{{ t('deviceManage.confirm') }}</el-button>
          </div>
          <template #reference>
            <el-button icon="delete" size="small" style="margin-left: 10px;" @click="deleteVisible=true"
                       :disabled="!multipleSelection.length">{{ t('deviceManage.formButtonDelete') }}
            </el-button>
          </template>
        </el-popover>
        <el-popconfirm :title="t('deviceManage.enableConfirmQuestion')" @confirm="handleBatchEnable">
          <template #reference>
            <el-button size="small" :disabled="multipleSelection.length < 1">{{
                t('deviceManage.formButtonEnable')
              }}
            </el-button>
          </template>
        </el-popconfirm>
        <el-popconfirm :title="t('deviceManage.disableConfirmQuestion')" @confirm="handleBatchDisable">
          <template #reference>
            <el-button size="small" :disabled="multipleSelection.length < 1">{{
                t('deviceManage.formButtonDisable')
              }}
            </el-button>
          </template>
        </el-popconfirm>
      </div>
      <el-table ref="multipleTable" style="width: 100%" tooltip-effect="dark" :data="tableData" row-key="id"
                height="700"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" min-width="30"/>
        <el-table-column align="left" label="ID" prop="id" min-width="50"/>
        <el-table-column align="left" :label="t('deviceManage.formBoxName')" prop="name" min-width="200">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <box-icon :id="scope.row.id" size="petit"/>
              <el-link @click="jump2device(scope.row)">
                {{ scope.row.name }}
              </el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formDeviceCode')" prop="deviceCode" min-width="110"/>
        <el-table-column align="left" :label="t('deviceManage.formBoxModel')" min-width="165">
          <template #default="{ row }">
            {{ this.t('dbDeviceModels.' + row.model.name) }}
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formBoxBodyModel')" min-width="140">
          <template #default="{row}">
            {{
              row.boxBodyModel === 0 ? '-' : [
                t('dbBoxBodyModels.立式'),
                t('dbBoxBodyModels.壁挂式'),
                t('dbBoxBodyModels.固封式'),
              ][row.boxBodyModel - 1]
            }}
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formCable')" prop="" min-width="200">
          <template #default="scope">{{ getCableName($store.getters['cables/getById'](scope.row.cableId)) }}</template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.formCompany')" prop="devCompany.name" min-width="200">
          <template #default="scope">
            {{ $store.getters['companies/getById'](scope.row.companyId).name }}
          </template>
        </el-table-column>
        <el-table-column type="expand" :label="t('deviceManage.expand')" width="100">
          <template #default="props">
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formGpsModel') }}:
                {{ $t('dbGpsModels.' + $store.getters['models/getGpsModelByIndex'](props.row.gpsmodel).name) }}
              </el-col>
              <el-col :span="6" v-if="props.row.gpsmodel==3">{{ t('deviceManage.formGpsDevId') }}: {{
                  props.row.gpsDevId
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formQueue') }}: {{ props.row.queue }}</el-col>
              <el-col :span="6">{{ t('deviceManage.formLength') }}: {{ props.row.length }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formManualGps') }}:</el-col>
              <el-col :span="6">{{ t('deviceManage.formLongitude') }}: {{ props.row.longitude }}</el-col>
              <el-col :span="6">{{ t('deviceManage.formLatitude') }}: {{ props.row.latitude }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formMaintenance') }}: {{ props.row.maintenance.name }}</el-col>
              <el-col :span="6">{{ t('deviceManage.formManufacture') }}: {{ props.row.manufacture.name }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formCreatedAt') }}: {{ formatDate(props.row.createdat) }}</el-col>
              <el-col :span="6">{{ t('deviceManage.formOperationAt') }}: {{
                  formatDate(props.row.operationat)
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="6">{{ t('deviceManage.formServiceLife') }}: {{ props.row.service_life }}</el-col>
              <el-col :span="6">UUID: {{ props.row.uuid }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="15">{{ t('deviceManage.formConjunct') }}: {{ props.row.conjunct }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="1"/>
              <el-col :span="15">{{ t('deviceManage.formComment') }}: {{ props.row.comment }}</el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.enable')" prop="enable" width="100">
          <template #default="scope">
            <el-switch v-model="scope.row.enable" size="small" active-color="#13ce66" inactive-color="#C0CCDA"
                       @click="enableDevDevicesFunc(scope.row.id, scope.row.enable)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="t('deviceManage.operations')" min-width="210">
          <template #default="scope">
            <el-button plain circle icon="pictureFilled" size="small" class="table-button"
                       @click="showUploadDialog(scope.row)"
                       :type="deviceImageCounts[scope.row.deviceCode]>0 ? 'success' : 'info'"
            />
            <el-button plain type="primary" icon="edit" size="small" class="table-button"
                       @click="updateDevDevicesFunc(scope.row)">{{ t('deviceManage.edit') }}
            </el-button>
            <el-button plain type="warning" icon="delete" size="small" class="table-button"
                       @click="deleteRow(scope.row)">{{ t('deviceManage.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="gva-pagination pager">
        <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page="page" :page-size="pageSize"
                       :page-sizes="[15, 30, 50, 100]" :total="total" @current-change="handleCurrentChange"
                       @size-change="handleSizeChange"/>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible"
               :before-close="closeDialog"
               @opened="onOpened()"
               :show-close="false"
               :close-on-click-modal="false"
               custom-class="intelligentDialog">
      <el-form :model="formData" label-position="top">
        <el-card>
          <template #header>
            <div><span>{{ t('deviceManage.dialogPropConfigTitle') }}</span></div>
          </template>
          <el-row>
            <el-col :span="10">
              <el-form-item :label="t('deviceManage.formBoxName')">
                <el-input v-model.trim="formData.name" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="8">
              <el-form-item :label="t('deviceManage.formCable')">
                <el-select :disabled="this.type==='update'" v-model="formData.cable" value-key="name">
                  <el-option
                      v-for="item in cables.list"
                      :key="item.name"
                      :label="getCableName(item)"
                      :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item :label="t('deviceManage.formBoxModel')">
                <el-select :disabled="this.type==='update'" v-model="formData.model" value-key="name">
                  <el-option
                      v-for="item in $store.getters['models/getGrounds']"
                      :key="item.name"
                      :label="$store.state.user.lang === 'zh' ? item.name : t('dbDeviceModels.'+item.name)"
                      :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formBoxBodyModel')">
                <el-select v-model="formData.boxBodyModel" value-key="name">
                  <el-option
                      v-for="item in [
                        {code: 1, name: '立式'},
                        {code: 2, name: '壁挂式'},
                        {code: 3, name: '固封式'},
                    ]"
                      :key="item.code"
                      :label="$store.state.user.lang === 'zh' ? item.name : t('dbBoxBodyModels.'+item.name)"
                      :value="item.code"
                  />
                </el-select>
              </el-form-item>

              <el-form-item :label="t('deviceManage.formCompany')">
                <el-select v-model="formData.devCompany" value-key="name" :disabled="type==='update'">
                  <template v-if="$store.state.user.userInfo.id===1  || type==='update'">
                    <template v-for="item in companies.list" :key="item.name">
                      <el-option :label="item.name" :value="item"/>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="item in $store.state.companies.list" :key="item.name">
                      <el-option v-if="item.id===user.userInfo.devCompanyId" :label="item.name" :value="item"/>
                    </template>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formMaintenance')">
                <el-select v-model="formData.maintenance" value-key="name">
                  <template v-for="item in companies.list" :key="item.name">
                    <el-option
                        v-if="isMaintainer(item.type)"
                        :label="item.name"
                        :value="item"
                    />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formManufacture')">
                <el-select v-model="formData.manufacture" value-key="name">
                  <template v-for="item in companies.list" :key="item.name">
                    <el-option
                        v-if="isManufacturer(item.type)"
                        :label="item.name"
                        :value="item"
                    />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formConjunct')">
                <el-input v-model.trim="formData.conjunct" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="1">
            </el-col>
            <el-col :span="7">
              <el-form-item :label="t('deviceManage.formQueue')">
                <el-input v-model.number="formData.queue" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formLength')">
                <el-input v-model.number="formData.length" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formOperationAt')">
                <el-date-picker v-model="formData.operationat" type="date" style="width:100%" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formServiceLife')">
                <el-input v-model.number="formData.serviceLife" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formDeviceCode')">
                <el-input v-model.trim="formData.deviceCode" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formComment')">
                <el-input v-model.trim="formData.comment" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="1">
            </el-col>
            <el-col :span="7">
              <el-form-item :label="t('deviceManage.formGpsModel')">
                <el-select v-model.number="formData.gpsmodel">
                  <el-option
                      v-for="item in models.gpsModels"
                      :key="item.value"
                      :label="$store.state.user.lang === 'zh' ? item.name : t('dbGpsModels.'+item.name)"
                      :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formGpsDevId')">
                <el-input v-model.number="formData.gpsDevId" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formLongitude')">
                <el-input v-model.trim="formData.longitude" clearable/>
              </el-form-item>
              <el-form-item :label="t('deviceManage.formLatitude')">
                <el-input v-model.trim="formData.latitude" clearable/>
              </el-form-item>
              <div class="tip" v-if="formData.gpsmodel === 3"><p>{{ t('deviceManage.messageGps3Tip') }}</p></div>
            </el-col>
          </el-row>
        </el-card>

      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">{{ t('deviceManage.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="enterDialog">{{ t('deviceManage.confirm') }}</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="uploadFormVisible" @close="uploadFormClose" class="upload-form">
      <input ref="uploaderRef" type="file" @change="handleChange" accept="image/*">
      <el-button @click="openUploader">{{ t('deviceManage.buttonUploadPhoto') }}</el-button>
      <el-divider/>
      <div style="display: flex; flex-wrap: wrap; flex-direction: row;">
        <div v-for="(f, i) in fileList" :key="i" style="display: flex; flex-direction: column; align-items: center;">
          <el-image :style="i===0? 'width: 320px; height: 320px;' : 'width: 140px; height: 140px;'"
                    style="margin-left: 10px; margin-top: 10px"
                    :zoom-rate="1.2"
                    fit="cover"
                    :src="getImagePath(f)"
                    :preview-src-list="fileList.map(val=>getImagePath(val))"
                    :initial-index="i"
          />
          <el-button @click="deleteImg(f)" size="small" type="danger" icon="delete" plain circle
                     style="margin-top: 5px"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
import {ElMessage, ElMessageBox} from 'element-plus'
import {ref} from 'vue'
import service from '@/utils/request'
import lodash from 'lodash'
import qs from 'qs'
import {parseTypeCode} from "@/utils/companyCode";
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import {getCableName} from '@/utils/cableNameGen'
import {getImageList, deleteFile, getCount} from "@/api/file";
import {store} from "@/store";
import axios from 'axios'
import {useRouter} from "vue-router";
import {formatDate} from '@/utils/format'
import {disableDevice, enableDevice} from "@/api/devices";
import {useI18n} from "vue-i18n";

export default {
  name: 'DevicesIntelligent',
  components: {BoxIcon},
  data() {
    const columnTableData = [];
    const columnDataItem = {
      name: "colName",
      type: 1,
      enable: 1,
      min: 1,
      max: 100,
    };
    columnTableData.push(lodash.cloneDeep(columnDataItem))
    const types = [{value: 0, label: 'float'},
      {value: 1, label: 'int',},
      {value: 2, label: 'bool',},]
    return {
      columnDataItem,
      columnTableData,
      types,
      dataFmt: {}
    }
  },
  methods: {
    onSelect() {
      let datacfgs = [];
      this.dataFmt.units.forEach(dataFmtItem => {
        if (dataFmtItem.data.enable === true) {
          datacfgs.push(dataFmtItem);
        }
      })
    },
    onAddColumn() {
      this.columnTableData.push(lodash.cloneDeep(this.columnDataItem));
    },
    onOpened() {
      let grounds = this.$store.getters['models/getGrounds']
      if (!this.formData.model && grounds.length > 0)
        this.formData.model = grounds[0];

      if (!this.formData.cable && this.cables.list.length > 0) {
        this.formData.cable = this.cables.list[0];
      }
    },
    // 重置
    onReset() {
      this.useSearchInfo = false
      this.searchInfo = {}
      this.onSubmit()
    },
    // 更新行
    async updateDevDevicesFunc(row) {
      this.type = 'update'
      this.formData = {...row}
      this.formData.cable = this.$store.getters['cables/getById'](row.cableId)
      if (!this.formData.cable.gndboxs) {
        this.formData.cable.gndboxs = [];
      }
      this.dialogFormVisible = true
    },

    // 弹窗确定
    async enterDialog() {
      if (!this.formData.model.id || !this.formData.devCompany.id) {
        ElMessage({
          type: 'error',
          message: this.t('deviceManage.messageEmptyFieldError')
        })
        return
      }
      if (!this.formData.longitude || !this.formData.latitude) {
        ElMessage({
          type: 'error',
          message: this.t('deviceManage.messageCoordinateEmptyError')
        })
        return
      }
      this.formData.cableId = this.formData.cable.id;
      this.formData.modelId = this.formData.model.id;
      this.formData.companyId = this.formData.devCompany.id;
      this.formData.maintenanceId = this.formData.maintenance.id;
      this.formData.manufacturerId = this.formData.manufacture.id;
//      this.formData.boxId = this.formData.ground.id;
      this.formData.queue = parseInt(this.formData.queue)


      let postData = lodash.cloneDeep(this.formData)
      delete postData.model;
      delete postData.cable;
      delete postData.ground;
      delete postData.maintenance;
      delete postData.manufacture;
      delete postData.createdat;
      delete postData.devCompany;
      delete postData.uuid;
      delete postData.enable;
      delete postData.devCfgs;
      delete postData.manufacture;
      if (this.type !== 'update') {
        delete postData.id;
      }

      service({
        url: "/api/v1/devices",
        method: this.type === 'update' ? 'patch' : "post",
        data: postData,
      }).catch((e) => {
        console.log(e);
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: this.t('deviceManage.createOrEditSuccess')
          })
          this.closeDialog()
          this.getTableData()
          this.$store.dispatch("cables/request", true);
        }
      }).catch((e) => {
        console.log(e);
      })

    },
    getCableName,
    getImageList,
  },
  computed: {
    ...mapState([
      'models', 'cables', 'companies', 'user'
    ]),
    ...mapGetters(
        {getModelsArray: 'models/getArray'},
    ),
  },

  setup() {
    const {t} = useI18n()

    const formDataOriginal = {
      name: t('deviceManage.newBoxNamePlaceholder'),
      // boxId: 0,
      enable: false,
      cableId: 1,
      cable: {},
      ground: {},
      model: {},
      modelId: 6,
      gpsmodel: 3,
      // 宜兴市坐标
      longitude: "119.823407",
      latitude: "31.340637",
      devId: 5,
      length: 200,
      companyId: 1,
      devCompany: {},
      maintenanceId: 1,
      maintenance: {},
      manufacturerId: 1,
      manufacture: {},
      queue: -1,
      serviceLife: 5,
      comment: '',
      boxBodyModel: 1,
    }

    const formData = ref(formDataOriginal)

    function isMaintainer(code) {
      return parseTypeCode(code).includes(1)
    }

    function isManufacturer(code) {
      return parseTypeCode(code).includes(2)
    }

// =========== 表格控制部分 ===========
    const page = ref(1)
    const total = ref(0)
    const pageSize = ref(15)
    const tableData = ref([])
    const searchInfo = ref({})
    const useSearchInfo = ref(false)

// 搜索
    const onSubmit = () => {
      page.value = 1
      useSearchInfo.value = true
      getTableData()
    }

// 分页
    const handleSizeChange = (val) => {
      pageSize.value = val
      getTableData()
    }

// 修改页面容量
    const handleCurrentChange = (val) => {
      page.value = val
      getTableData()
    }

// 查询
    const getTableData = async () => {
      let params = {
        page: page.value,
        pageSize: pageSize.value,
        ext: true,
        type: 2,
      }
      if (useSearchInfo.value) {
        params = {...params, ...searchInfo.value}
      }
      service({
        url: '/api/v1/devices',
        method: "get",
        params,
        paramsSerializer: params => {
          return qs.stringify(params, {indices: false})
        }
      })
          .then((res) => {
            if (res.code === 0) {
              if (res.data.list) {
                tableData.value = res.data.list;
                total.value = res.data.total;
                page.value = res.data.page;
                pageSize.value = res.data.pageSize;
                getDeviceImageCounts();
              } else {
                tableData.value = [];
                total.value = 0;
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
    };

    getTableData();

// 多选数据
    const multipleSelection = ref([])
// 多选
    const handleSelectionChange = (val) => {
      multipleSelection.value = val
    }

// 删除行
    const deleteRow = (row) => {
      ElMessageBox.confirm(t('deviceManage.deleteConfirmQuestion'), t('deviceManage.deleteConfirmTitle'), {
        confirmButtonText: t('deviceManage.confirm'),
        cancelButtonText: t('deviceManage.cancel'),
        type: 'warning'
      }).then(() => {
        deleteDevDevicesFunc(row)
      })
    }

// 批量删除控制标记
    const deleteVisible = ref(false)

// 多选删除
    const onDelete = async () => {
      if (multipleSelection.value.length === 0) {
        ElMessage({
          type: 'warning',
          message: t('deviceManage.messageDeleteNoSelection')
        })
        return
      }
      multipleSelection.value &&
      multipleSelection.value.map(item => {
        // console.log("item.id:", item.id)
        service({
          url: '/api/v1/devices/' + item.id,
          method: 'delete',
          data: {id: item.id}
        }).then((res) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: t('deviceManage.deleteSuccess')
            })
            if (tableData.value.length === multipleSelection.value.length && page.value > 1) {
              page.value--
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      })

      deleteVisible.value = false
      getTableData()
    }

// 行为控制标记（弹窗内部需要增还是改）
    const type = ref('')

// 删除行
    const deleteDevDevicesFunc = async (row) => {
      service({
        url: '/api/v1/devices/' + row.id,
        method: 'delete'
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: t('deviceManage.deleteSuccess')
          })

          if (tableData.value.length === 1 && page.value > 1) {
            page.value--
          }
          getTableData();
        }
      }).catch((e) => {
        console.log(e);
      });
    }

// 使能某个设备
    const enableDevDevicesFunc = async (id, enable) => {
      service({
        url: '/api/v1/devices/' + id,
        method: 'patch',
        params: {
          enable: enable
        },
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: t('deviceManage.updateSuccess')
          })
        }
      }).catch((e) => {
        console.log(e);
      });
    }

// 弹窗控制标记
    const dialogFormVisible = ref(false)

// 打开弹窗
    const openDialog = () => {
      type.value = 'create'
      formData.value.name = "device-" + (tableData.value.length + 1)
      dialogFormVisible.value = true
    }

// 关闭弹窗
    const closeDialog = () => {
      formData.value = formDataOriginal
      dialogFormVisible.value = false
    }

    // 上传图片相关
    const uploaderRef = ref()
    const uploadFormVisible = ref(false)
    const fileList = ref([])
    let file
    let reader = new FileReader(), img = new Image();
    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')
    let devicePhotoOpened
    const maxWidth = 960, maxHeight = 960;

    function getImage() {
      getImageList(devicePhotoOpened).then((r) => {
        if (r.code === 0) {
          fileList.value = r.data
        }
      })
    }

    function getImagePath(filename) {
      if (process.env.NODE_ENV === 'development') {
        return 'https://www.dataf.store/images/' + filename
      } else {
        return 'images/' + filename
      }
    }

    function showUploadDialog(row) {
      devicePhotoOpened = row.deviceCode
      getImage()
      uploadFormVisible.value = true
    }

    function resizeImg() {
      // 抄自 zhangxinxu
      let originalRatio = img.width / img.height
      let targetWidth = img.width,
          targetHeight = img.height
      if (img.width > maxWidth || img.height > maxHeight) {
        if (originalRatio > maxWidth / maxHeight) {
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (1 / originalRatio));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originalRatio));
        }
      }

      canvas.width = targetWidth
      canvas.height = targetHeight
      context.clearRect(0, 0, targetWidth, targetHeight)
      context.drawImage(img, 0, 0, targetWidth, targetHeight)
      canvas.toBlob((blob) => {
        postBlob(`/api/v1/file?deviceCode=${devicePhotoOpened}`, blob).then((r) => {
          getImage()
        })
      }, 'image/png');
    }

    function postBlob(url, blob) {
      let formData = new FormData()
      let data = new File([blob], `${devicePhotoOpened}.png`)

      formData.append('file', data)
      return axios({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": 'multipart/form-data',
          "x-token": store.getters['user/token'],
        }
      })
    }

    function handleChange(event) {
      // 抄自 zhangxinxu
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      img.onload = resizeImg

      file = event.target.files[0]
      if (file.type.indexOf("image") == 0) {
        reader.readAsDataURL(file);
      }
    }

    function deleteImg(filename) {
      deleteFile(filename).then(r => {
        getImage()
      })
    }

    function uploadFormClose() {
      fileList.value = []
      getDeviceImageCounts()
    }

    function openUploader() {
      uploaderRef.value.click()
    }

    // 批量获取设备图片数量
    const deviceImageCounts = ref({})

    function getDeviceImageCounts() {
      let codes = []
      for (let device of tableData.value) {
        let c = device.deviceCode.trim()
        if (c.length === 0) {
          continue
        }
        codes.push(c)
      }
      if (codes.length === 0) {
        return
      }
      getCount(codes).then(r => {
        if (r.code === 0) {
          deviceImageCounts.value = r.data
        }
      })
    }

    const router = useRouter()

    function jump2device(box) {
      let box_ = store.getters['cables/getDeviceById'](box.id)
      if (box_.subdevs?.length > 0) {
        store.commit('cables/setCurrentDevice', box_.subdevs[0].id)
      }
      router.push('../devicecenter')
    }

    // 批量使能
    function handleBatchEnable() {
      let tasks = multipleSelection.value.reduce((prev, item) => {
        return [...prev, enableDevice(item.id)]
      }, [])
      Promise.all(tasks).then(() => {
        getTableData()
      })
    }

    function handleBatchDisable() {
      let tasks = multipleSelection.value.reduce((prev, item) => {
        return [...prev, disableDevice(item.id)]
      }, [])
      Promise.all(tasks).then(() => {
        getTableData()
      })
    }

    return {
      formData,
      page,
      total,
      pageSize,
      tableData,
      searchInfo,
      useSearchInfo,
      onSubmit,
      handleSizeChange,
      handleCurrentChange,
      getTableData,
      multipleSelection,
      handleSelectionChange,
      deleteRow,
      deleteVisible,
      onDelete,
      type,
      deleteDevDevicesFunc,
      enableDevDevicesFunc,
      dialogFormVisible,
      openDialog,
      closeDialog,
      isMaintainer,
      isManufacturer,
      // 上传图片相关
      uploadFormVisible,
      fileList,
      showUploadDialog,
      handleChange,
      uploadFormClose,
      getImagePath,
      deleteImg,
      uploaderRef,
      openUploader,
      deviceImageCounts,
      getDeviceImageCounts,
      jump2device,
      formatDate,
      handleBatchEnable,
      handleBatchDisable,
      t,
    }
  }
}
</script>


<style>
.tip {
  text-align: right;
  font-size: 15px;
  color: firebrick;
}

.pager {
  z-index: 99;
}

.search-box {
  height: 50px;
}

.box {
  height: 820px;
}

.upload-form {
  width: 800px !important;
}

.uploader {
  font-size: 20px;
}

input[type="file"] {
  display: none;
}
</style>
